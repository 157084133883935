import React, { useEffect, useState } from 'react';
import {
    Grid,
    Card,
    CardContent,
    Typography,
    Collapse,
    IconButton,
    Box,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';
import urlConfig from '../../../urlConfig';

const Overview = () => {
    const [events, setEvents] = useState([]);
    const [expandedEvent, setExpandedEvent] = useState(null);

    const handleExpandClick = (eventId) => {
        setExpandedEvent(expandedEvent === eventId ? null : eventId);
    };

    const organizer = JSON.parse(localStorage.getItem('user'));
    useEffect(() => {
        const fetchData = async () => {
            try {
                const eventResponse = await fetch(`${urlConfig.apiBaseUrl}/Event/Customer/${organizer.id}`);
                const eventData = await eventResponse.json();
                setEvents(eventData);
            } catch (error) {
                console.error('Fehler beim Abrufen der Daten:', error);
            }
        };

        fetchData();
    }, [organizer.id]);

    const ExpandMore = styled((props) => {
        const { expand, ...other } = props;
        return <IconButton {...other} />;
    })(({ theme, expand }) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    return (
        <div className="mt-[6%]">
            <Grid item xs={12} sm={12}>
                <Card sx={{ borderRadius: '1rem', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)' }}>
                    <CardContent>
                        {events && events.length > 0 ? (
                            <>
                                <Typography variant="h5">Geplante Events</Typography>
                                {events.map((event) => (
                                    <div key={event.id}>
                                        <Grid
                                            container
                                            direction="row"
                                            justifyContent="space-between"
                                            alignItems="center"
                                        >
                                            <Grid item>
                                                <Typography variant="h6">{event.name}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <ExpandMore
                                                    expand={expandedEvent === event.id}
                                                    onClick={() => handleExpandClick(event.id)}
                                                >
                                                    <ExpandMoreIcon />
                                                </ExpandMore>
                                            </Grid>
                                        </Grid>
                                        <Collapse
                                            in={expandedEvent === event.id}
                                            timeout="auto"
                                            unmountOnExit
                                        >
                                            <Box p={2}>
                                                <Typography variant="body1" gutterBottom>
                                                    <strong>Kurzbeschreibung: </strong> <br />
                                                    {event.description || 'N/A'}
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    <strong>Detaillierte Beschreibung: </strong> <br />
                                                    {event.detailed_description || 'N/A'}
                                                </Typography>
                                                <Typography variant="body1" gutterBottom>
                                                    <strong>Termine: </strong>
                                                </Typography>
                                                <div className="space-y-4">
                                                    {event.eventDates?.length > 0 ? (
                                                        event.eventDates.map((eventDate, index) => (
                                                            <div
                                                                key={index}
                                                                className="p-4 bg-white-200 rounded-lg shadow-md"
                                                            >
                                                                <div className="font-medium">
                                                                    <strong>
                                                                        {eventDate.date
                                                                            ? new Date(eventDate.date).toLocaleDateString()
                                                                            : 'N/A'}
                                                                    </strong>
                                                                    {eventDate.date && (
                                                                        <span>
                                                                            {' '}
                                                                            -{' '}
                                                                            {new Date(eventDate.date).toLocaleTimeString()}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                                {eventDate.location?.description && (
                                                                    <div className="mt-2 text-sm text-gray-600">
                                                                        <strong>Standort:</strong>{' '}
                                                                        {eventDate.location.description}
                                                                    </div>
                                                                )}
                                                            </div>
                                                        ))
                                                    ) : (
                                                        <div className="p-4 bg-white rounded-lg shadow-md">
                                                            <strong>N/A</strong>
                                                        </div>
                                                    )}
                                                </div>
                                            </Box>
                                        </Collapse>
                                    </div>
                                ))}
                            </>
                        ) : (
                            <Typography variant="h5" color="textSecondary" className="py-[4%]">
                                Sie haben noch keine Events angelegt!
                            </Typography>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </div>
    );
};

export default Overview;