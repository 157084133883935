import React, {useEffect, useState} from 'react';
import { Grid, Button, Typography, Card } from '@mui/material';
import {useLocation, useNavigate} from 'react-router-dom';
import { useEventsProvider } from '../EventsProvider';

const EventOverview = () => {
    const location = useLocation();
    const { eventsData } = useEventsProvider();
    const [events, setEvents] = useState( eventsData || []);  // funktioniert
    const [activeTab, setActiveTab] = useState(0);
    const navigate = useNavigate();

    const getEventDateRange = (eventDates) => {
        if (!eventDates || eventDates.length === 0) {
            return { firstDate: null, lastDate: null };
        }
        const sortedDates = [...eventDates].sort((a, b) => new Date(a.date) - new Date(b.date));
        const firstDate = new Date(sortedDates[0].date).toLocaleDateString('de-DE');
        const lastDate = new Date(sortedDates[sortedDates.length - 1].date).toLocaleDateString('de-DE');
        return { firstDate, lastDate };
    };

    // handleEditEventClicked
    const handleEventClick = (eventProp) => {
        navigate('/organizer/events', {
            state: { activeTab: 2, selectedEvent: eventProp.name, activeTabId: activeTab },
        });
    };

    useEffect(() => {
        if (location.state && location.state.events) {
            const { events } = location.state.events;
            setEvents(events || []);
        }

        if (location.state && location.state.activeTab !== undefined) {
            setActiveTab(location.state.activeTab);
        }
    }, [location.state]);

    useEffect(() => {
        // Fallback: Überprüfen, ob `eventsData` korrekt ist
        if (!events.length && eventsData) {
            setEvents(eventsData);
        }
    }, [eventsData, events]);

    return (
        <div>
            {/* Eventübersicht */}
            <Typography variant="h4" gutterBottom>
                Ihre Events
            </Typography>
            <Grid container spacing={2} style={{justifyContent: 'center'}}>
                {events && events.length > 0 ? (
                    events.map((event, index) => {
                        const { firstDate, lastDate } = getEventDateRange(event.eventDates);
                        return (
                            <Grid item xs={12} key={index} style={{ marginBottom: '16px' }}>
                                <Card
                                    sx={{
                                        padding: '16px',
                                        borderRadius: '8px',
                                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
                                        position: 'relative',
                                    }}
                                >
                                    <Typography variant="h6">{event.name}</Typography>
                                    <Typography variant="body1" color="textSecondary" gutterBottom>
                                        {firstDate && lastDate
                                            ? `Zeitraum: ${firstDate} - ${lastDate}`
                                            : 'Keine Termine vorhanden'}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary">
                                        Standorte: {event.eventDates && event.eventDates.length > 0
                                        ? event.eventDates
                                            .map((eventDate) => eventDate.location.description || 'Nicht angegeben')
                                            .filter(
                                                (location, idx, self) => location && self.indexOf(location) === idx
                                            )
                                            .join(', ')
                                        : 'Nicht angegeben'}
                                    </Typography>

                                    {/* Bearbeiten-Button */}
                                    <Button
                                        variant="outlined"
                                        color="secondary"
                                        sx={{
                                            position: 'absolute',
                                            bottom: '16px',
                                            right: '16px',
                                        }}
                                        onClick={() => handleEventClick(event)}
                                    >
                                        Bearbeiten
                                    </Button>
                                </Card>
                            </Grid>
                        );
                    })
                ) : (
                    <Typography variant="body1" color="textSecondary" style={{marginTop: '3%', marginBottom: '30%'}}>
                        Keine Events gefunden.
                    </Typography>
                )}
            </Grid>

            {/* Hinzufügen-Button */}
            <Button
                variant="contained"
                color="primary"
                style={{
                    marginTop: '16px',
                    padding: '10px 20px',
                    borderRadius: '8px',
                }}
                onClick={() => navigate('/organizer/events', { state: { activeTab: 1 } })}
            >
                Event hinzufügen
            </Button>
        </div>
    );
};

export default EventOverview;