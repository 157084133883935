import React, {useEffect, useRef, useState} from "react";
import SeatRow from "./Row";

const SeatingGroupLabels = ({rows, groupPosition, seatSize, minSpacing, groupName}) => {
    // Ermittlung der Reihe mit den meisten Sitzen
    const maxSeatsRow = rows.reduce((maxRow, currentRow) =>
            currentRow.seats.length > maxRow.seats.length ? currentRow : maxRow
        , rows[0]);

    const rowLabels = rows.map((row, rowIndex) => {
        const y = groupPosition.y + rowIndex * (seatSize + minSpacing) + seatSize / 2;
        return (
            <text
                key={`row-label-${rowIndex}`}
                x={groupPosition.x - 10} // Links von den Sitzen
                y={y}
                fontSize={12}
                textAnchor="end"
                alignmentBaseline="middle"
                fill="black"
            >
                {row.rowNumber}
            </text>
        );
    });

    const columnLabels = maxSeatsRow?.seats.map((_, colIndex) => {
        const x = groupPosition.x + colIndex * (seatSize + minSpacing) + seatSize / 2;
        return (
            <text
                key={`col-label-${colIndex}`}
                x={x}
                y={groupPosition.y - 10} // Über den Sitzen
                fontSize={12}
                textAnchor="middle"
                fill="black"
            >
                {colIndex + 1}
            </text>
        );
    });

    return (
        <g>
            {/* Gruppenname */}
            {groupName &&
                <text
                    x={groupPosition.x}
                    y={groupPosition.y - 30} // Über der Gruppe
                    fontSize={16}
                    fontWeight="bold"
                    fill="black"
                >
                    {groupName}
                </text>
            }
            {/* Reihen-Beschriftungen */}
            {rowLabels}
            {/* Spalten-Beschriftungen */}
            {columnLabels}
        </g>
    );
};

const SeatingGroup = ({
                          group,
                          svgWidth,
                          svgHeight,
                          seatSize,
                          minSpacing,
                          toggleSeat,
                          selectedSeats,
                          handleElementClick,
                          selectedElement
                      }) => {
    const groupDesign = group.designInformation;

    // Center Position for the group
    const [centerX, setCenterX] = useState(groupDesign.relativeCenterX * svgWidth);
    const [centerY, setCenterY] = useState(groupDesign.relativeCenterY * svgHeight);

    // Drag state
    const [dragStart, setDragStart] = useState({x: 0, y: 0});
    const [isDragging, setIsDragging] = useState(false);
    const [dragThresholdReached, setDragThresholdReached] = useState(false);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setDragThresholdReached(false);
        setDragStart({x: e.clientX, y: e.clientY});
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            const deltaX = e.clientX - dragStart.x;
            const deltaY = e.clientY - dragStart.y;

            if (!dragThresholdReached && (Math.abs(deltaX) > 5 || Math.abs(deltaY) > 5)) {
                setDragThresholdReached(true);
            }

            setCenterX(centerX + deltaX);
            setCenterY(centerY + deltaY);
            setDragStart({x: e.clientX, y: e.clientY});
        }
    };

    const handleMouseUp = () => {
        if (isDragging) {
            setIsDragging(false);
        }
    };

    const handleClick = () => {
        if (!dragThresholdReached) {
            handleElementClick();
        }
    };

    // Finde die Reihe mit den meisten Sitzen
    const maxSeatsRow = group.rows.reduce((maxRow, currentRow) =>
            (currentRow.seats.length + currentRow.seatOffset) > (maxRow.seats.length + maxRow.seatOffset) ? currentRow : maxRow
        , group.rows[0]);

    // Fixierte Breite und Höhe basierend auf der maximalen Sitzanzahl
    const fixedWidth = (maxSeatsRow?.seats.length + maxSeatsRow.seatOffset) * (seatSize + minSpacing) - minSpacing || 0;
    const fixedHeight = group.rows.length * (seatSize + minSpacing) - minSpacing || 0;

    const groupPosition = {
        x: centerX - fixedWidth / 2,
        y: centerY - fixedHeight / 2,
    };

    const rotationAngle = groupDesign.rotation || 0;
    const isSelected = selectedElement && selectedElement.svgId === group.svgId;


    return (
        <g
            transform={`rotate(${rotationAngle}, ${centerX}, ${centerY})`}
        >
            {/* Labels */}
            <SeatingGroupLabels
                rows={group.rows}
                groupPosition={groupPosition}
                seatSize={seatSize}
                minSpacing={minSpacing}
                groupName={groupDesign.showName ? group.name : null}
            />

            {/* Rows and Seats */}
            {group.rows.map((row, rowIndex) => (
                <SeatRow
                    key={`group-${group.svgId}-row-${rowIndex}`}
                    row={{...row, group: group.name}}
                    rowIndex={rowIndex}
                    seatSize={seatSize}
                    minSpacing={minSpacing}
                    toggleSeat={toggleSeat}
                    selectedSeats={selectedSeats}
                    groupPosition={groupPosition}
                    groupDesign={groupDesign}
                    maxSeatsRow={maxSeatsRow}
                />
            ))}

            {/* Surrounding Rectangle */}
            <rect
                x={groupPosition.x - 25}
                y={groupPosition.y - 25}
                width={fixedWidth + 30} // Add some padding
                height={fixedHeight + 30}
                fill={"transparent"}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
                onClick={handleClick}
                stroke={isSelected ? "black" : "none"}
                strokeWidth={isSelected ? 2 : 0}
                style={{cursor: "move"}}
            />
        </g>
    );
};

export default SeatingGroup;
