import React, {useEffect, useState} from "react";

const Stage = ({stageInfo, svgWidth, svgHeight, handleElementClick, selectedElement}) => {
    const [dragStart, setDragStart] = useState({x: 0, y: 0});
    const [isDragging, setIsDragging] = useState(false);
    const [dragThresholdReached, setDragThresholdReached] = useState(false);

    const [stageWidth, setStageWidth] = useState(stageInfo.relativeSizeX / 100 * svgWidth);
    const [stageHeight, setStageHeight] = useState(stageInfo.relativeSizeY / 100 * svgHeight);

    const [stageCenterX, setStageCenterX] = useState(stageInfo.relativeCenterX * svgWidth);
    const [stageCenterY, setStageCenterY] = useState(stageInfo.relativeCenterY * svgHeight);
    const [stageX, setStageX] = useState(stageCenterX - stageWidth / 2);
    const [stageY, setStageY] = useState(stageCenterY - stageHeight / 2);

    const text = stageInfo.text;

    useEffect(() => {
        setStageX(stageCenterX - stageWidth / 2);
        setStageY(stageCenterY - stageHeight / 2);
    }, [stageCenterX, stageCenterY, stageHeight, stageWidth]);

    useEffect(() => {
        setStageWidth(stageInfo.relativeSizeX / 100 * svgWidth);
        setStageHeight(stageInfo.relativeSizeY / 100 * svgHeight);
    }, [stageInfo.relativeSizeX, stageInfo.relativeSizeY]);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setDragThresholdReached(false);
        setDragStart({x: e.clientX, y: e.clientY});
    };

    const handleMouseMove = (e) => {
        if (isDragging) {
            const deltaX = e.clientX - dragStart.x;
            const deltaY = e.clientY - dragStart.y;

            if (!dragThresholdReached && (Math.abs(deltaX) > 5 || Math.abs(deltaY) > 5)) {
                setDragThresholdReached(true);
            }

            setStageCenterX(stageCenterX + deltaX);
            setStageCenterY(stageCenterY + deltaY);
            setDragStart({x: e.clientX, y: e.clientY});
        }
    };

    const handleMouseUp = () => {
        if (isDragging) {
            setIsDragging(false);
        }
    };

    const handleClick = () => {
        if (!dragThresholdReached) {
            handleElementClick();
        }
    };

    const isSelected = selectedElement && selectedElement.svgId === stageInfo.svgId;

    return (
        <g>
            {/* Umrandung für ausgewähltes Element */}
            {isSelected && (
                <rect
                    x={stageX - 1} // 5px größer als das eigentliche Element
                    y={stageY - 1}
                    width={stageWidth + 2} // 10px mehr für die Breite
                    height={stageHeight + 2} // 10px mehr für die Höhe
                    fill="none"
                    stroke="black"
                    strokeWidth={2}
                />
            )}
            {/* Stage-Hintergrund */}
            <rect
                x={stageX}
                y={stageY}
                width={stageWidth}
                height={stageHeight}
                fill="#a8a8a8"
                opacity={stageInfo.stageOpacity / 100}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onClick={handleClick}
                style={{cursor: "move"}}
            />
            {/* Stage-Text */}
            <text
                x={stageCenterX}
                y={stageCenterY}
                fill="white"
                fontSize={stageInfo.fontSize}
                textAnchor="middle"
                dominantBaseline="middle"
            >
                {text}
            </text>
        </g>
    );
};

export default Stage;
