import React, { useState } from "react";
import { Button, Box, Typography } from "@mui/material";
import StageEditor from "./ElementEditor/StageEditor";
import SeatingGroupEditor from "./ElementEditor/SeatingGroupEditor";
import AddSeatingGroup from "./ElementEditor/AddSeatingGroup";

const EditorPanel = ({ addStage, selectedElement, updateElement, onAddSeatingGroup }) => {
    const [showAddSeatingGroup, setShowAddSeatingGroup] = useState(false);

    const handleAddSeatingGroup = (groupData) => {
        onAddSeatingGroup(groupData);
        setShowAddSeatingGroup(false);
    };

    return (
        <Box
            sx={{
                width: 300,
                padding: 2,
                borderRight: "1px solid gray",
                height: "100vh", // Stellt sicher, dass der gesamte Editor-Bereich sichtbar ist
                overflowY: "auto", // Aktiviert das Scrollen innerhalb des EditorPanels
                position: "relative", // Falls zusätzliche Positionierungen erforderlich sind
                marginBottom: '50%'
            }}
        >
            <Typography variant="h6" gutterBottom>
                Tools
            </Typography>

            {!showAddSeatingGroup ? (
                <Box display="flex" flexDirection="column" gap={2}>
                    <Button variant="contained" onClick={() => setShowAddSeatingGroup(true)}>
                        Add Seating Group
                    </Button>
                    <Button variant="contained" onClick={addStage}>
                        Add Stage
                    </Button>
                </Box>
            ) : (
                <AddSeatingGroup
                    onAdd={handleAddSeatingGroup}
                    onCancel={() => setShowAddSeatingGroup(false)}
                />
            )}

            {selectedElement && (
                <Box mt={4} sx={{ flexGrow: 1 }}>
                    {selectedElement.type === "stage" && (
                        <StageEditor stage={selectedElement} updateStage={updateElement} />
                    )}

                    {selectedElement.type === "seatingGroup" && (
                        <SeatingGroupEditor
                            seatingGroup={selectedElement}
                            updateSeatingGroup={updateElement}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
};

export default EditorPanel;
