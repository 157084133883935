import React from 'react';
import './Event.css';
import { useNavigate } from 'react-router-dom';

const Event = ({ id,imgSrc, title, description, detailedDescription, organizer, eventDates, dateRange }) => {
    const navigate = useNavigate();

    const handleClick = () => {
        const eventDetails = {
            id,
            imgSrc,
            title,
            description,
            detailedDescription,
            organizer,
            eventDates,
            name: title,
            picture: imgSrc
        };
        navigate('/eventDetails', { state: eventDetails });
    };

    return (
        <div className="event" onClick={handleClick}>
            <img src={imgSrc} alt="Event Picture missing..." />
            <div className="event-details">
                <h3>{title}</h3>
                <p>{description}</p>
                <p>{`Organisiert von: ${organizer}`}</p>
                <p>{dateRange}</p>
            </div>
        </div>
    );
};

export default Event;