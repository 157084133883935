import React, { createContext, useContext, useState, useEffect } from 'react';
import urlConfig from "../../urlConfig";

const EventsContext = createContext();

export const EventsProvider = ({ children }) => {
    const [eventsData, setEventsData] = useState(null);
    const organizer = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        const fetchData = async () => {
            try {
                const eventResponse = await fetch(`${urlConfig.apiBaseUrl}/Event/Organizer/${organizer.id}`);
                const eventData = await eventResponse.json();
                setEventsData(eventData);
            } catch (error) {
                console.error('Fehler beim Abrufen der Daten:', error);
            }
        };

        fetchData();
    }, [organizer.id]);

    const onEventChange = (updatedEvent) => {
        setEventsData((prevEvents) => {
            if (!Array.isArray(prevEvents)) return [updatedEvent];

            const eventIndex = prevEvents.findIndex(event => event.id === updatedEvent.id);
            if (eventIndex > -1) {
                const updatedEvents = [...prevEvents];
                updatedEvents[eventIndex] = { ...prevEvents[eventIndex], ...updatedEvent };
                return updatedEvents;
            }

            return [...prevEvents, updatedEvent];
        });
    };

    return (
        <EventsContext.Provider value={{ eventsData, onEventChange }}>
            {children}
        </EventsContext.Provider>
    );
};

// Hook zum Zugriff auf den Kontext
export const useEventsProvider = () => {
    const context = useContext(EventsContext);
    if (!context) {
        throw new Error('useEventsProvider must be used within an EventsProvider');
    }
    return context;
};