import { createTheme, ThemeProvider } from '@mui/material/styles';

const globalTheme = createTheme({
    typography: {
        // Setzt eine globale Schriftgröße für body1, die häufig in Material-UI-Komponenten verwendet wird
        body1: {
            fontSize: '1.2rem',  // Die gewünschte Schriftgröße
        },
    },
    components: {
        // Für die ListItemText-Komponenten die Schriftgröße auch für span anpassen
        MuiListItemText: {
            styleOverrides: {
                root: {
                    fontSize: '1.2rem',
                    '& span': {
                        fontSize: '1.2rem',
                    },
                },
            },
        },
    },
});

export default globalTheme;