import React, { useEffect, useState } from 'react';
import './Slideshow.css';

const images = [
    require('../../assets/images/Fischer/group.jpg'),
    require('../../assets/images/Fischer/closeUp.jpg'),
    require('../../assets/images/Fischer/quadratic.jpg')
];

const Slideshow = () => {
    const [index, setIndex] = useState(0);

    useEffect(() => {
        const interval = setInterval(() => {
            setIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 3000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="event-slideshow">
            <div className="slideshow" style={{ transform: `translateX(${-index * 33.3333}%)` }}>
                {images.map((image, idx) => (
                    <img key={idx} src={image} alt={`Event Image ${idx + 1}`} className="slideshow-image" />
                ))}
            </div>
        </div>
    );
};

export default Slideshow;
