import React, {useEffect, useState} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import {Switch} from "@mui/material";

const Header = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const [isOrganizerView, setIsOrganizerView] = useState(location.pathname === '/organizer/overview');
    const [userType, setUserType] = useState(localStorage.getItem('userType'));
    const [firstName, setFirstName] = useState(findFirstName);
    const [isAnimating, setIsAnimating] = useState(false);

    function findFirstName() {
        const user = JSON.parse(localStorage.getItem('user'));
        if (userType === "CUSTOMER") return user.firstName;
        if (userType === "ORGANIZER") return user.name;
        if (userType === "ADMIN") return "Admin";
    }

    useEffect(() => {
        setIsOrganizerView(location.pathname.startsWith('/organizer'));
    }, [location.pathname]);

    useEffect(() => {
        const handleClickOutside = (event) => {
            const dropdown = document.querySelector('.dropdown');
            if (dropdown && !dropdown.contains(event.target) && !event.target.closest('.user-info')) {
                setDropdownVisible(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    const toggleDropdown = () => {
        setDropdownVisible((prev) => !prev);
    };

    const handleLogout = () => {
        localStorage.removeItem('user');
        localStorage.removeItem('token');
        localStorage.removeItem('userType');
        localStorage.removeItem('events');
        setFirstName(null);
        setUserType(null);
        navigate('/');
    };

    const handleViewSwitch = () => {
        setIsOrganizerView((prevView) => {
            const newView = !prevView;
            setDropdownVisible(false);

            const paths = {
                CUSTOMER: newView ? '/customer' : '/',
                ORGANIZER: newView ? '/organizer/overview' : '/',
                ADMIN: newView ? '/adminView' : '/',
            };

            navigate(paths[userType] || '/');
            return newView;
        });
    };

    const getPageLabel = () => {
        const { pathname } = location;

        if (pathname === '/') return 'Hauptseite';
        if (pathname.startsWith('/eventDetails')) return 'Details & Termine';
        if (pathname.startsWith('/seatingPlan')) return 'Saalplan Übersicht';
        if (pathname.startsWith('/organizer')) return 'Organisation';
        if (pathname.startsWith('/login') || pathname.startsWith('/register')) return null;
        if (pathname.startsWith('/admin')) return 'Admin Ansicht';

        return 'Zurück';
    };

    const getUserLabel = () => {
        switch (userType) {
            case 'CUSTOMER':
                return 'Kundenansicht';
            case 'ORGANIZER':
                return 'Organisatorenansicht';
            case 'ADMIN':
                return 'Adminansicht';
            case 'LOCALSELLER':
                return 'POS-Verkäufer Ansicht';
            default:
                return 'Unbekannt';
        }
    };

    return (
        <header className="bg-black-100 text-white w-full p-3 fixed z-50 top-0">
            <table className="w-full text-center">
                <tbody>
                <tr>
                    <td className="w-1/3 px-4 text-2xl font-semibold text-left">
                        {getPageLabel() && (
                            <span className="cursor-pointer" onClick={() => window.history.back()}>
                                {getPageLabel()}
                            </span>
                        )}
                    </td>

                    <td className="w-1/3 text-3xl font-bold" onClick={() => navigate("/")}>
                        <span className="cursor-pointer">
                            Ticket4U
                        </span>
                    </td>

                    <td className="w-1/3 text-2xl font-semibold text-right px-4">
                        {firstName ? (
                            <span className="relative cursor-pointer user-info" onClick={toggleDropdown}>
                                <span>Hallo, {firstName}</span>
                                <span className="ml-2">▼</span>
                                {dropdownVisible && (
                                    <div
                                        className="dropdown absolute right-0 top-full mt-2 bg-white text-black rounded-lg shadow-lg p-4 z-50 min-w-[150px] text-lg">
                                        {userType !== "CUSTOMER" && (
                                            <div className="flex justify-between items-center mb-4">
                                                <span>{getUserLabel()}</span>
                                                <Switch
                                                    checked={isOrganizerView}
                                                    onChange={handleViewSwitch}
                                                    color="primary"
                                                    disabled={isAnimating}
                                                />
                                            </div>
                                        )}
                                        <button
                                            className="w-full py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                                            onClick={handleLogout}
                                        >
                                            Logout
                                        </button>
                                    </div>
                                )}
                            </span>
                        ) : (
                            location.pathname !== "/login" &&
                            location.pathname !== "/register" && (
                                <span
                                    className="cursor-pointer"
                                    onClick={() =>
                                        navigate("/login", {
                                            state: {redirectTo: window.location.pathname},
                                        })
                                    }
                                >
                                    Login
                                </span>
                            )
                        )}
                    </td>
                </tr>
                </tbody>
            </table>
        </header>
    );
};

export default Header;