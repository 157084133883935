import React from "react";

const SeatRow = ({
                     row,
                     rowIndex,
                     seatSize,
                     minSpacing,
                     toggleSeat,
                     selectedSeats,
                     groupPosition,
                     showTooltip,
                     hideTooltip
                 }) => {
    return row.seats.map((seat, seatIndex) => {
        const x = groupPosition.x + seatIndex * (seatSize + minSpacing);
        const y = groupPosition.y + rowIndex * (seatSize + minSpacing);
        const isAvailable = seat.status === "AVAILABLE";
        const fillColor = isAvailable
            ? selectedSeats.some(s => s.id === seat.id)
                ? 'yellow'
                : 'white'
            : 'red';

        return (
            <g
                key={seat.id}
                onClick={() => toggleSeat(seat, isAvailable, row.rowNumber, row.group)}
                onMouseEnter={() => showTooltip(seat, x, y, row.rowNumber, row.group)}
                onMouseLeave={hideTooltip}
            >
                <rect
                    id={seat.seatNumber}
                    x={x}
                    y={y}
                    width={seatSize}
                    height={seatSize}
                    fill={fillColor}
                    stroke="black"
                />
            </g>
        );
    });
};

export default SeatRow;