import React, {useEffect, useState} from 'react';
import {Card, CardContent, Grid, Typography} from '@mui/material';

const Members = () => {
    const [localSellers, setLocalSellers] = useState([]);

    const organizer = JSON.parse(localStorage.getItem('user'));

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLocalSellers(organizer.localSellers);
            } catch (error) {
                console.error('Fehler beim Abrufen der Daten:', error);
            }
        };

        fetchData();
    }, []);

    return (
        <div>
            {/* LocalSeller list */}
            <Grid item>
                <Card sx={{borderRadius: '1rem',
                    marginTop: '4rem',
                    boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'}}>
                <CardContent>
                        {localSellers.length > 0 ? (
                            <>
                                <Typography variant="h5" style={{ marginBottom: '2%' }}>Angelegte lokale Verkäufer</Typography>
                                {localSellers.map((localSeller) => (
                                    <Typography key={localSeller.id} style={{ marginBottom: '0.5%' }}>
                                        {localSeller.name}: {localSeller.phone_number} <strong>Rolle:</strong> Lokaler Verkäufer
                                    </Typography>
                                ))}
                            </>
                        ) : (
                            <Typography variant="h5">Noch keine Mitarbeiter angelegt!</Typography>
                        )}
                    </CardContent>
                </Card>
            </Grid>
        </div>
    );
};

export default Members;