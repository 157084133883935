import {Autocomplete, Box, Button, Card, CardContent, Divider, Grid, TextField, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import urlConfig from "../../../urlConfig";
import {useEventsProvider} from "../EventsProvider";
import withWidth from "@mui/material/Hidden/withWidth";

const AddEventComponent = () => {
    // CONSTANTS
    const { onEventChange } = useEventsProvider()
    const { eventsData } = useEventsProvider()
    const [events, setEvents] = useState(eventsData || []);
    const [locations, setLocations] = useState([]);
    const organizer = JSON.parse(localStorage.getItem('user'));
    const [newEvent, setNewEvent] = useState({
        name: '',
        date: '',
        description: '',
        detailedDescription: '',
        location: {id: '', description: ''}
    });
    const [dateTimeRows, setDateTimeRows] = useState([{date: '', time: '', location: ''}]);
    const [formErrors, setFormErrors] = useState({
        name: false,
        description: false,
        detailedDescription: false,
        date: false,
        time: false
    });

    /*useEffect(() => {
        const savedLocations = JSON.parse(localStorage.getItem('locationList')) || [];
        setLocations(Array.isArray(savedLocations) ? savedLocations : []);
        if (savedLocations.length > 0) {
            const firstLocation = savedLocations[0];
            setNewEvent(prevEvent => ({
                ...prevEvent,
                location: {
                    ...prevEvent.location,
                    description: firstLocation.name
                }
            }));
        }
    }, []);*/
    useEffect(() => {
        const savedLocations = JSON.parse(localStorage.getItem('locationList')) || []; // Ensure it defaults to an empty array if null
        setLocations(Array.isArray(savedLocations) ? savedLocations : []);

        console.log("savedLocations.length ", savedLocations.length);
        console.log("savedLocations ", JSON.stringify(savedLocations));
        console.log("savedLocations[0] ", savedLocations[0]);

        if (savedLocations.length > 0) {
            // Den ersten Standort als Standardwert setzen
            setNewEvent(prevEvent => ({
                ...prevEvent,
                location: savedLocations[0] // Setze den ersten Standort als Standardwert
            }));
        } else {
            // Optional: Fallback, wenn keine Standorte vorhanden sind
            setNewEvent(prevEvent => ({
                ...prevEvent,
                location: { id: '', description: '' }
            }));
        }
    }, []);

    const handleDateTimeChange = (index, field, value) => {
        const updatedRows = [...dateTimeRows];
        updatedRows[index][field] = value;
        setDateTimeRows(updatedRows);

        if (field === "location") {
            updateEventLocation(index, value);
        }
    };

    const updateEventLocation = (index, locationDescription) => {
        const updatedEvents = [...events];

        const eventIndex = index;
        const eventDate = updatedEvents[eventIndex]?.eventDates?.[0];

        if (eventDate) {
            eventDate.location.description = locationDescription;
            setEvents(updatedEvents);
            onEventChange(updatedEvents);
        }
    };

    const handleAddRow = () => {
        setDateTimeRows([...dateTimeRows, {date: '', time: '', location: ''}]);
    };

    const handleEventChange = (field, value) => {
        setNewEvent({...newEvent, [field]: value});
    };

    const validateForm = () => {
        const errors = {
            name: !newEvent.name,
            description: !newEvent.description,
            detailedDescription: !newEvent.detailedDescription,
            date: dateTimeRows.some(row => !row.date),
            time: dateTimeRows.some(row => !row.time)
        };
        setFormErrors(errors);
        return Object.values(errors).every((error) => !error);
    };

    const appendNewEvent = (updatedEvent) => {
        setEvents((prevEvents) => Array.isArray(prevEvents) ? [...prevEvents, updatedEvent] : [updatedEvent]);
        onEventChange(updatedEvent);
    };

    const handleAddEvent = async () => {
        if (!validateForm()) return;

        const eventData = {
            name: newEvent.name,
            description: newEvent.description,
            detailed_description: newEvent.detailedDescription,
            picture: "https://ticket4u.at/pictures/concert.jpg",
            organizerId: organizer.id,
        };

        try {
            const createdEvent = await addEventApiCall(eventData);

            if (createdEvent && createdEvent.id) {
                const eventId = createdEvent.id;
                const random = Math.random();
                const dateData = dateTimeRows.map(row => ({
                    date: new Date(`${row.date}T${row.time}:00Z`),
                    locationId: random < 0.5 ? 1 : 2,
                }));

                const eventDates = await addDatesToEventApiCall(eventId, dateData);

                const updatedEvent = { ...createdEvent, eventDates };
                onEventChange(updatedEvent);
                appendNewEvent(updatedEvent);

                setNewEvent({
                    name: '',
                    date: '',
                    description: '',
                    detailedDescription: '',
                    location: {id: '', description: ''}
                });
                setDateTimeRows([{date: '', time: '', location: ''}]);
            } else {
                console.error('Failed to retrieve event ID');
            }
        } catch (error) {
            console.error('Error adding event and dates:', error);
        }
    };

    const addEventApiCall = async (eventData) => {
        try {
            const response = await fetch(`${urlConfig.apiBaseUrl}/Event`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('token'),
                },
                body: JSON.stringify(eventData),
            });
            if (response.ok) {
                const data = await response.json();
                return data;
            } else {
                console.error('Failed to add event:', response.statusText);
                return null;
            }
        } catch (error) {
            console.error('Error adding event:', error);
            return null;
        }
    };

    const addDatesToEventApiCall = async (eventId, dateData) => {
        try {
            const response = await fetch(`${urlConfig.apiBaseUrl}/Date/dates/${eventId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: localStorage.getItem('token'),
                },
                body: JSON.stringify(dateData),
            });
            if (response.ok) {
                return await response.json();
            } else {
                console.error('Failed to add dates:', response.statusText);
            }
        } catch (error) {
            console.error('Error adding dates to event:', error);
        }
    };

    return (
        <div>
            {/* Add Event */}
            <Grid item xs={12} style={{marginBottom: '1%'}}>
                <Card sx={{borderRadius: '1rem', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)'}}>
                    <CardContent>
                        <Typography variant="h5" gutterBottom>
                            Event hinzufügen
                        </Typography>
                        <TextField
                            label="Name des Events"
                            type={"search"}
                            value={newEvent.name}
                            onChange={(e) => handleEventChange('name', e.target.value)}
                            fullWidth
                            margin="normal"
                            error={formErrors.name}
                            helperText={formErrors.name && "Event Name ist erforderlich"}
                        />
                        <Box display="flex" alignItems="center" gap={2}>
                            {/* Description Box */}
                            <Box flex={1}>
                                <TextField
                                    label="Kurzbeschreibung"
                                    value={newEvent.description}
                                    onChange={(e) => handleEventChange('description', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={1}
                                    error={formErrors.description}
                                    helperText={formErrors.description && "Kurzbeschreibung ist erforderlich"}
                                />
                                <TextField
                                    label="Detailierte Beschreibung"
                                    value={newEvent.detailedDescription}
                                    onChange={(e) => handleEventChange('detailedDescription', e.target.value)}
                                    fullWidth
                                    margin="normal"
                                    multiline
                                    rows={4}
                                    error={formErrors.detailedDescription}
                                    helperText={formErrors.detailedDescription && "Detailierte Beschreibung ist erforderlich"}
                                />
                            </Box>
                            <Divider orientation="vertical" flexItem/>
                            {/* Date & Location Box */}
                            <Box flex="0 0 40%">
                                <Box display="flex" flexDirection="column" gap={2}>
                                    {dateTimeRows.map((row, index) => (
                                        <Box key={index} display="flex" alignItems="center" gap={2} mt={2}>
                                            <TextField
                                                label="Datum"
                                                type="date"
                                                value={row.date}
                                                onChange={(e) => handleDateTimeChange(index, 'date', e.target.value)}
                                                fullWidth
                                                style={{width: '35%'}}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={formErrors.date}
                                            />
                                            <TextField
                                                label="Uhrzeit"
                                                type="time"
                                                value={row.time}
                                                onChange={(e) => handleDateTimeChange(index, 'time', e.target.value)}
                                                fullWidth
                                                style={{width: '18%', flex: '0 1 auto'}}
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                                error={formErrors.time}
                                            />
                                            <Autocomplete
                                                options={locations}
                                                getOptionLabel={(option) => option.id || ''} // Hier das name-Feld entfernt
                                                value={locations.find(loc => loc.id === row.location) || null} // Wert hier mit id statt name abgleichen
                                                onChange={(event, newValue) => handleDateTimeChange(index, 'location', newValue ? newValue.id : '')} // Wert mit id aktualisieren
                                                renderInput={(params) => <TextField {...params} label="Standort" fullWidth />}
                                                style={{ width: '30%' }}
                                            />
                                        </Box>
                                    ))}
                                    <Button
                                        variant="standard"
                                        color="primary"
                                        onClick={handleAddRow}
                                        style={{height: 'fit-content', fontSize: 22}}
                                    >
                                        +
                                    </Button>
                                </Box>
                            </Box>
                        </Box>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleAddEvent}
                            style={{marginTop: '15px'}}
                        >
                            Event hinzufügen
                        </Button>
                    </CardContent>
                </Card>
            </Grid>
        </div>
    );
}

export default AddEventComponent;