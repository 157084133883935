import React, {useState} from 'react';
import QrReader from 'modern-react-qr-reader'; // Plugin geändert
import urlConfig from '../../urlConfig';

const ScanQRCode = () => {
    const [scanResult, setScanResult] = useState('');
    const [isValid, setIsValid] = useState(false);
    const [isScanning, setIsScanning] = useState(true); // Steuerung, ob gescannt werden kann
    const [scannerKey, setScannerKey] = useState(0); // Key für das Zurücksetzen des Scanners

    const handleScan = async (data) => {
        if (data && isScanning) {
            setIsScanning(false); // Verhindert doppelte Verarbeitung
            const hashValue = data; // modern-react-qr-reader gibt den Wert direkt zurück
            try {
                // HTTP GET-Anfrage an den Endknoten
                const response = await fetch(`${urlConfig.apiBaseUrl}/ticket/UUID?uuid=${hashValue}`, {
                    method: 'GET',
                    headers: {
                        'Accept': 'application/json',
                    },
                });

                // Überprüfe, ob die Antwort erfolgreich war
                if (!response.ok) {
                    console.error('Server error:', response.status, response.statusText);
                    setIsValid(false);
                    setScanResult('Server error: Unable to verify hash');
                    return;
                }

                // Verarbeite die JSON-Antwort
                const result = await response.json();
                console.log('Response from server:', result);

                // Setze die Zustände basierend auf der Server-Antwort
                setScanResult(result);
                setIsValid(result.valid || false); // Beispiel: `isValid` erwartet in der Antwort
                setIsScanning(true);
            } catch (error) {
                // Fehlerbehandlung bei Netzwerkproblemen oder anderen Ausnahmen
                console.error('Error during fetch:', error);
                setScanResult('Network error: Unable to verify hash');
                setIsValid(false);
            }
        }
    };

    const handleError = (err) => {
        console.error(err);
    };

    const handleNextScan = () => {
        setScanResult('');
        setIsValid(false);

        // Sicherheitsverzögerung hinzufügen, um sicherzustellen, dass das Video bereit ist
        setTimeout(() => {
            setIsScanning(true);
        }, 1000); // Verzögerung von 1 Sekunde (mindestens)

    };

    const handleInvalidate = async () => {
        if (scanResult && scanResult.id) {
            try {
                const response = await fetch(`${urlConfig.apiBaseUrl}/ticket/invalidate/${scanResult.id}`, {
                    method: 'POST',
                    headers: {
                        'Accept': '*/*',
                    },
                    body: '', // Der Body ist leer, wie in der CURL-Anfrage angegeben
                });

                if (response.ok) {
                    alert('Das Ticket wurde erfolgreich entwertet.');
                } else {
                    console.error('Fehler beim Entwerten des Tickets:', response.status, response.statusText);
                    alert('Entwerten fehlgeschlagen. Bitte versuchen Sie es erneut.');
                }
            } catch (error) {
                console.error('Netzwerkfehler beim Entwerten des Tickets:', error);
                alert('Netzwerkfehler. Bitte überprüfen Sie die Verbindung.');
            }
        } else {
            alert('Ungültige Ticket-Daten. Entwerten nicht möglich.');
        }

        handleNextScan(); // Scanner für den nächsten QR-Code vorbereiten
    };


    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
            <h2>Scanne den QR-Code</h2>
            <QrReader
                delay={2000}
                key={scannerKey}
                style={{
                    width: '100%',
                    maxWidth: '500px',
                    border: '1px solid #ccc',
                    borderRadius: '10px',
                }}
                onScan={(data) => {
                    if (data) handleScan(data); // Sicherheitsschicht hinzugefügt
                }}
                onError={handleError}
                facingMode="environment" // Außenkamera verwenden
            />


            {scanResult && isValid && (
                <div style={{marginTop: '20px', textAlign: 'center'}}>
                    <p style={{color: 'green', fontSize: '18px', fontWeight: 'bold'}}>Ticket ist gültig!</p>
                    <div style={{textAlign: 'left', marginTop: '10px'}}>
                        <p><strong>Event:</strong> {scanResult.eventName}</p>
                        <p><strong>Datum:</strong> {new Date(scanResult.eventDate).toLocaleDateString()}</p>
                        <p><strong>Kaufdatum:</strong> {new Date(scanResult.buyingDate).toLocaleDateString()}</p>
                        <p><strong>Preis:</strong> {scanResult.price} €</p>
                        <p><strong>Sitznummer:</strong> {scanResult.seatNumber}</p>
                        <p><strong>Reihe:</strong> {scanResult.rowNumber}</p>
                        <p><strong>Sitzgruppe:</strong> {scanResult.seatingGroup}</p>
                        <p><strong>Sitztyp:</strong> {scanResult.seatType}</p>
                    </div>
                </div>
            )}
            {scanResult && !isValid && (
                <div style={{marginTop: '20px', textAlign: 'center'}}>
                    <p style={{color: 'red', fontSize: '18px', fontWeight: 'bold'}}>Ticket ist ungültig!</p>
                </div>
            )}
            <div style={{marginTop: '30px', display: 'flex', gap: '20px'}}>
                <button
                    onClick={handleInvalidate}
                    style={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        backgroundColor: 'red',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    Entwerten
                </button>
                <button
                    onClick={handleNextScan}
                    style={{
                        padding: '10px 20px',
                        fontSize: '16px',
                        backgroundColor: 'blue',
                        color: 'white',
                        border: 'none',
                        borderRadius: '5px',
                        cursor: 'pointer'
                    }}
                >
                    Nächsten QR-Code scannen
                </button>
            </div>
        </div>
    );
};

export default ScanQRCode;
