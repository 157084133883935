import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    Card,
    Collapse,
    Divider,
    Grid,
    IconButton,
    Input,
    TextField,
    Typography
} from "@mui/material";
import {ExpandLess} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import {useLocation, useNavigate} from "react-router-dom";

const EditEventComponent = ({events: eventsProp, locations: locationsProp}) => {
    // CONSTANTS
    const navigate = useNavigate();
    const [events, setEvents] = useState(eventsProp);
    const routerLocation = useLocation();
    const [locations, setLocations] = useState(locationsProp);

    // EVENT HANDLING
    useEffect(() => {
        setEvents(eventsProp);
        if (routerLocation.state) {
            const { selectedEvent: selectedEventName, activeTab: tab } = routerLocation.state;
            const matchedEvent = eventsProp.find(event => event.name === selectedEventName);
            setSelectedEvent(matchedEvent || null);

            if (matchedEvent) {
                localStorage.setItem('selectedEvent', selectedEventName);
                // TODO: Tabs richtig wieder setzen
                localStorage.setItem('activeTab', tab || 0);
            }
        } else {  // Falls kein Event über Props kommt, aus dem localStorage laden
            const savedEventName = localStorage.getItem('selectedEvent');
            if (savedEventName) {
                const matchedEvent = eventsProp.find(event => event.name === savedEventName);
                setSelectedEvent(matchedEvent || null);
            }
        }
    }, [eventsProp, routerLocation.state]);

    const handleEventChange = (event, newValue) => {
        setSelectedEvent(newValue);
        if (newValue) {
            navigate("/organizer/events", {
                state: { activeTab: 2, selectedEvent: newValue.name },
            });
            localStorage.setItem("selectedEvent", newValue.name);
        }
    };

    // EDIT EVENT CONSTANTS
    const [selectedEvent, setSelectedEvent] = React.useState(null);

    const defaultSeatClass = {name: "Standard", price: 10, category: "Standard"};
    const [seatClasses, setSeatClasses] = useState([defaultSeatClass]);

    const updateSeatClass = (index, field, value) => {
        const updatedSeatClasses = [...seatClasses];
        updatedSeatClasses[index][field] = value;
        setSeatClasses(updatedSeatClasses);
    };

    const addSeatClass = () => {
        setSeatClasses([...seatClasses, {name: '', price: ''}]);
    };

    const removeSeatClass = (index) => {
        setSeatClasses(seatClasses.filter((_, i) => i !== index));
    };

    // EDIT TERMIN CONSTANTS
    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const handleDeleteEvent = (index) => {
        const updatedEventDates = selectedEvent.eventDates.filter((_, i) => i !== index);
        setSelectedEvent({...selectedEvent, eventDates: updatedEventDates});
    };

    const ExpandMore = styled((props) => {
        const {expand, ...other} = props;
        return <IconButton component="div" {...other} />;
    })(({theme, expand}) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    const handleImageChange = (index, event) => {
        const updatedSeatClasses = [...seatClasses];
        const file = event.target.files[0];
        if (file) {
            updatedSeatClasses[index].image = URL.createObjectURL(file); // Create a preview URL for the image
        }
        setSeatClasses(updatedSeatClasses);
    };

    const removeLocation = (index) => {
        const updatedCustomLocations = customLocations.filter((_, i) => i !== index);
        setCustomLocations(updatedCustomLocations);
    };

    const updateLocation = (index, field, value) => {
        const updatedSeatClasses = [...seatClasses];
        updatedSeatClasses[index][field] = value;
        setSeatClasses(updatedSeatClasses);
    };

    const [customLocations, setCustomLocations] = useState([
        { name: 'Standort A', image: null },
        { name: 'Standort B', image: null },
    ]);

    const addCustomLocation = () => {
        setCustomLocations([...customLocations, {name: '', image: ''}]);
    };

    return (
        <div>
            {/* Event bearbeiten */}
            <Grid item xs={12} sm={12} style={{marginBottom: '3%'}}>
                <Card sx={{borderRadius: '1rem', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', padding: 2}}>
                    <Grid container spacing={2} alignItems="center">

                        {/* Titel */}
                        <Grid item xs={12} sm={12} textAlign="center">
                            <Typography variant="h5" gutterBottom>
                                Event bearbeiten
                            </Typography>
                        </Grid>

                        {/* Dropdown-Menüs */}
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                options={events}
                                getOptionLabel={(option) => option.name || ''}
                                onChange={handleEventChange}
                                value={selectedEvent || null}
                                renderInput={(params) => <TextField {...params} label="Event auswählen" fullWidth />}
                            />
                        </Grid>
                        <Typography variant="body1" color="textSecondary" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            marginTop: '1.5%',
                            marginBottom: '3%'
                        }}>
                            Hinweis: Diese Einstellungen sind für alle Termine dieses Events gültig.
                        </Typography>

                        <>
                            {selectedEvent && selectedEvent.eventDates?.length > 0 ? (
                                <>
                                    {/* Event allgemeine Informationen bearbeiten */}
                                    {selectedEvent.eventDates.map((eventDate, index) => (
                                        <Grid
                                            item
                                            container
                                            spacing={2}
                                            xs={12}
                                            key={index}
                                            style={{marginBottom: '10px',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                        }}
                                        >
                                            {/* Datum */}
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Datum"
                                                    type="date"
                                                    value={eventDate.date ? new Date(eventDate.date).toISOString().split('T')[0] : ''}
                                                    fullWidth
                                                    InputLabelProps={{shrink: true}}
                                                    onChange={(e) => {
                                                        const updatedEventDates = [...selectedEvent.eventDates];
                                                        updatedEventDates[index].date = e.target.value;
                                                        setSelectedEvent({
                                                            ...selectedEvent,
                                                            eventDates: updatedEventDates,
                                                        });
                                                    }}
                                                />
                                            </Grid>

                                            {/* Uhrzeit */}
                                            <Grid item xs={2}>
                                                <TextField
                                                    label="Uhrzeit"
                                                    type="time"
                                                    value={eventDate.date ? new Date(eventDate.date).toISOString().split('T')[1]?.substring(0, 5) : ''}
                                                    fullWidth
                                                    InputLabelProps={{shrink: true}}
                                                    onChange={(e) => {
                                                        const updatedEventDates = [...selectedEvent.eventDates];
                                                        const date = new Date(eventDate.date || new Date());
                                                        date.setHours(parseInt(e.target.value.split(':')[0], 10));
                                                        date.setMinutes(parseInt(e.target.value.split(':')[1], 10));
                                                        updatedEventDates[index].date = date.toISOString();
                                                        setSelectedEvent({
                                                            ...selectedEvent,
                                                            eventDates: updatedEventDates,
                                                        });
                                                    }}
                                                />
                                            </Grid>

                                            {/* Standort */}
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Standort"
                                                    placeholder="Standort eingeben"
                                                    fullWidth
                                                    value={eventDate.location.description || ''}
                                                    onChange={(e) => {
                                                        const updatedEventDates = [...selectedEvent.eventDates];
                                                        updatedEventDates[index].location.description = e.target.value;
                                                        setSelectedEvent({
                                                            ...selectedEvent,
                                                            eventDates: updatedEventDates,
                                                        });
                                                    }}
                                                />
                                            </Grid>

                                            {/* Pfeil zum Ein-/Ausklappen */}
                                            <Grid item xs={2} style={{textAlign: 'right'}}>
                                                <IconButton component="div" onClick={() => toggleExpand(index)}>
                                                    {expandedIndex === index ? <ExpandLess/> : <ExpandMore/>}
                                                </IconButton>
                                            </Grid>

                                            {/* Eingeklappte Zusatzinformationen */}
                                            <Grid item xs={12}>
                                                <Collapse in={expandedIndex === index}>
                                                    <Grid container spacing={2} style={{marginTop: '10px'}}>
                                                        {/* Kurzbeschreibung */}
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="Kurzbeschreibung"
                                                                placeholder="Kurze Beschreibung eingeben"
                                                                fullWidth
                                                                value={eventDate.shortDescription || ''}
                                                                onChange={(e) => {
                                                                    const updatedEventDates = [...selectedEvent.eventDates];
                                                                    updatedEventDates[index].shortDescription = e.target.value;
                                                                    setSelectedEvent({
                                                                        ...selectedEvent,
                                                                        eventDates: updatedEventDates,
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>

                                                        {/* Langbeschreibung */}
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="Langbeschreibung"
                                                                placeholder="Detaillierte Beschreibung eingeben"
                                                                fullWidth
                                                                multiline
                                                                rows={3}
                                                                value={eventDate.longDescription || ''}
                                                                onChange={(e) => {
                                                                    const updatedEventDates = [...selectedEvent.eventDates];
                                                                    updatedEventDates[index].longDescription = e.target.value;
                                                                    setSelectedEvent({
                                                                        ...selectedEvent,
                                                                        eventDates: updatedEventDates,
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>

                                                        {/* Löschen-Button */}
                                                        <Grid item xs={12} style={{textAlign: 'right'}}>
                                                            <Button
                                                                variant="outlined"
                                                                color="error"
                                                                onClick={() => handleDeleteEvent(index)}
                                                            >
                                                                Termin löschen
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Collapse>
                                            </Grid>
                                        </Grid>
                                    ))}

                                    {/* Locations verwalten */}
                                    <Grid item xs={12}>
                                        <Divider sx={{marginY: 2}}/>
                                        <Typography variant="h6" gutterBottom>
                                            Standorte verwalten
                                        </Typography>

                                        {customLocations.length === 0 ? (
                                            <Typography variant="body1" color="textSecondary">
                                                Sie <strong>müssen</strong> noch mindestens einen Standort anlegen!
                                            </Typography>
                                        ) : (
                                            customLocations.map((customLocation, index) => (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems="center"
                                                    key={index}
                                                    style={{marginTop: '15px'}}
                                                >
                                                    <Grid item xs={12} sm={5}>
                                                        <TextField
                                                            label="Name des Standorts"
                                                            fullWidth
                                                            value={customLocation.name}
                                                            onChange={(e) => updateLocation(index, 'name', e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={5}>
                                                        {/* Image Chooser */}
                                                        <Input
                                                            type="file"
                                                            accept="image/*"
                                                            onChange={(e) => handleImageChange(index, e)}
                                                            fullWidth
                                                        />
                                                        {customLocation.image && (
                                                            <img
                                                                src={customLocation.image}
                                                                alt={`Preview for ${customLocation.name}`}
                                                                style={{ marginTop: '10px', width: '100%', maxHeight: '150px', objectFit: 'cover' }}
                                                            />
                                                        )}
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <Button
                                                            variant="outlined"
                                                            color="error"
                                                            fullWidth
                                                            onClick={() => removeLocation(index)}
                                                        >
                                                            Entfernen
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        )}

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{marginTop: 2}}
                                            onClick={addCustomLocation}
                                            style={{marginTop: '2%'}}
                                        >
                                            Neuen Standort hinzufügen
                                        </Button>
                                    </Grid>

                                    {/* Sitzklassen verwalten */}
                                    <Grid item xs={12}>
                                        <Divider sx={{marginY: 2}}/>
                                        <Typography variant="h6" gutterBottom>
                                            Sitzklassen verwalten
                                        </Typography>

                                        {seatClasses.length === 0 ? (
                                            <Typography variant="body1" color="textSecondary">
                                                Sie <strong>müssen</strong> noch mindestens eine Sitzklasse anlegen!
                                            </Typography>
                                        ) : (
                                            seatClasses.map((seatClass, index) => (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems="center"
                                                    key={index}
                                                    style={{marginTop: '15px'}}
                                                >
                                                    <Grid item xs={12} sm={5}>
                                                        <TextField
                                                            label="Name der Sitzklasse"
                                                            fullWidth
                                                            value={seatClass.name}
                                                            onChange={(e) => updateSeatClass(index, 'name', e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={5}>
                                                        <TextField
                                                            label="Preis der Sitzklasse (€)"
                                                            type="number"
                                                            fullWidth
                                                            value={seatClass.price}
                                                            onChange={(e) => updateSeatClass(index, 'price', e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <Button
                                                            variant="outlined"
                                                            color="error"
                                                            fullWidth
                                                            onClick={() => removeSeatClass(index)}
                                                        >
                                                            Entfernen
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        )}

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{marginTop: 2}}
                                            onClick={addSeatClass}
                                            style={{marginTop: '2%'}}
                                        >
                                            Neue Sitzklasse hinzufügen
                                        </Button>
                                    </Grid>

                                    {/* Saalplan bearbeiten */}
                                    <Grid item xs={12} style={{marginBottom: '2%'}}>
                                        <Divider sx={{marginY: 2, marginBottom: '3%'}}/>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => navigate('/organizer/seatingPlanEditor', {
                                                state: { events: events, selectedEvent: selectedEvent  },
                                            })}
                                        >
                                            Saalplan Editor öffnen
                                        </Button>
                                        <Typography variant="body1" color="textSecondary" style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginTop: '5px',
                                        }}>
                                            Hinweis: Der hier erstellte Saalplan gilt für alle Termine des Events.
                                        </Typography>
                                    </Grid>
                                </>
                            ) : (
                                // Alternativer Text
                                <Grid item xs={12}>
                                    <Divider sx={{marginY: 2}}/>
                                    <Typography variant="body1" color="textSecondary">
                                        Bitte wählen Sie das Event aus, welches Sie bearbeiten möchten!
                                    </Typography>
                                </Grid>
                            )}
                        </>
                    </Grid>
                </Card>
            </Grid>
        </div>
    )
        ;
}

export default EditEventComponent;
