import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useLocation, useNavigate} from 'react-router-dom';
import Header from "../HeaderComponents/Header";
import PaymentComponent from "../PaymentComponent/paymentComponent";
import urlConfig from '../../urlConfig';
import SeatingPlan from "./components/SeatingPlan";


const SeatMap = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [seatMapData, setSeatMapData] = useState(null);
    const [selectedSeats, setSelectedSeats] = useState(location.state?.selectedSeats || []);
    const Customer = JSON.parse(localStorage.getItem("user"));
    const {date, eventid} = location.state || {};

    const containerRef = useRef(null);
    const [svgSize, setSvgSize] = useState({width: 0, height: 0});

    const seatSize = useMemo(() => svgSize.width * 0.03, [svgSize.width]); // 3% of SVG width
    const minSpacing = useMemo(() => svgSize.width * 0.01, [svgSize.width]); // 1% of SVG width


    const fetchSeatMapData = (i) => {
        fetch(`${urlConfig.apiBaseUrl}/Date/${i}`)
            .then(response => response.json())
            .then(data => {
                setSeatMapData(data);
            })
            .catch(error => console.error('Error fetching seating plans:', error));
    };

    useEffect(() => {
        if (date) {
            fetchSeatMapData(date.id);
        }
    }, [date]);

    useEffect(() => {
        const handleResize = () => {
            if (containerRef.current) {
                const width = containerRef.current.offsetWidth;
                const height = containerRef.current.offsetHeight;
                setSvgSize({width, height});
            }
        };

        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const buySelectedSeats = () => {
        if (!selectedSeats.length) return;
        /*
        if (!Customer) {
            navigate("/login", {
                state: {
                    redirectTo: "/seatingPlan",
                    seatingPlanState: {selectedSeats, date, eventid},
                }
            });
            return;
        }
         */
        navigate("/orderoverview", {
            state: {
                selectedSeats,
                date,
                eventid,
                totalAmount: selectedSeats.reduce((total, seat) => total + seat.type.price, 0)
            }
        });
    };

    /*const buySelectedSeats = async () => {
        if (!selectedSeats.length) return;
        if (!Customer) {
            navigate("/login", {
                state: {
                    redirectTo: "/seatingPlan",
                    seatingPlanState: { selectedSeats, date, eventid },
                }
            });
            return;
        }

        setSelectedSeats([]); // Reset selected seats
        const UUIDs = await Promise.all(
            selectedSeats.map((seat) =>
                fetch(`${urlConfig.apiBaseUrl}/ticket`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        "customerID": Customer.id,
                        "eventID": eventid,
                        "dateID": date.id,
                        "seatID": seat.id
                    })
                })
                    .then(response => response.text())
                    .catch(error => {
                        console.error('Error sending POST request:', error);
                        return null;
                    })
            )
        );
        navigate("/qrcode", {state: UUIDs});
    };
        const totalAmount = selectedSeats.reduce((total, seat) => total + seat.type.price, 0);
        const { createPayment } = PaymentComponent({ amount: totalAmount });

        const paymentSuccess = await createPayment();

        if (paymentSuccess) {
            const UUIDs = await Promise.all(
                selectedSeats.map((seat) =>
                    fetch(`http://localhost:8080/ticket`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify({
                            customerID: Customer.id,
                            eventID: eventid,
                            dateID: date.id,
                            seatID: seat.id,
                            // seatType: seat.type.name, seatPrice: seat.type.price,
                        })
                    })
                        .then(response => response.text())
                        .catch(error => {
                            console.error('Error sending POST request:', error);
                            return null;
                        })
                )
            );
            navigate("/qrcode", { state: UUIDs });
        } else {
            console.error("Payment failed, not proceeding with ticket creation.");
        }
    };*/

    const toggleSeat = (seat, isAvailable, rowNumber, group) => {
        if (isAvailable) {
            setSelectedSeats((prevSelected) => {
                const seatIndex = prevSelected.findIndex(s => s.id === seat.id);
                if (seatIndex === -1) {
                    return [...prevSelected, {...seat, rowNumber, group}];
                } else {
                    return prevSelected.filter(s => s.id !== seat.id);
                }
            });
        }
    };

    const resetPurchasedSeats = () => {
        date.seatingPlan.seatingGroups.forEach(group => {
            group.rows.forEach(row => {
                row.seats.forEach(seat => {
                    seat.status = "AVAILABLE";
                });
            });
        });

        fetch(`${urlConfig.apiBaseUrl}/Date/${date.id}`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(date.seatingPlan)
        })
            .then(response => {
                if (response.ok) {
                    console.log(`resetPurchasedSeats succeeded with status: ${response.status}`);
                } else {
                    console.error(`resetPurchasedSeats failed with status: ${response.status}, ${response.statusText}`);
                }
            })
            .catch(error => {
                console.error('Error during fetching resetPurchasedSeats:', error);
            });
    };

    const seatingGroups = seatMapData?.seatingPlan?.seatingGroups || [];
    const stages = seatMapData?.seatingPlan?.stages || []; // Handle multiple stages

    return (
        <div>
            <Header/>
            <div className="flex justify-between mt-20 ml-14 gap-4">
                <div ref={containerRef} className="w-9/12 pr-4 border-r border-gray-300 h-[80vh]">
                    {svgSize.width > 0 && svgSize.height > 0 && (
                        <SeatingPlan
                            seatingGroups={seatingGroups}
                            seatSize={seatSize}
                            minSpacing={minSpacing}
                            svgWidth={svgSize.width}
                            svgHeight={svgSize.height}
                            toggleSeat={toggleSeat}
                            selectedSeats={selectedSeats}
                            stages={stages}
                        />
                    )}
                </div>
                <div className="w-2/5 pl-4 flex flex-col justify-between mt-14 items-center">
                    <h2 id="selectedSeatsTitle" className="w-full text-xl">Ihre ausgewählten Sitzplätze:</h2>
                    <ul className="">
                        {selectedSeats.map((seat) => (
                            <li key={seat.id} className="border border-gray-500 bg-white-400 p-4 my-2 shadow-sm rounded-md">
                                <div className="flex items-center space-x-4">
                                    <span className="px-2 py-1">Reihe: {seat.rowNumber}</span>
                                    <span className="px-2 py-1">Sitz: {seat.seatNumber}</span>
                                    <span className="px-2 py-1">Gruppe: {seat.group}</span>
                                </div>
                            </li>
                        ))}
                    </ul>
                    <button
                        className="mt-auto bg-blue-500 text-white py-2 px-4 rounded cursor-pointer"
                        onClick={buySelectedSeats}
                    >
                        {`${selectedSeats.length} Tickets für €${selectedSeats.reduce((total, seat) => total + seat.type.price, 0)}`}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default SeatMap;
