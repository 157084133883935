import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Sidebar from './componets/Sidebar';
import Header from "../HeaderComponents/Header";
import { EventsProvider } from './EventsProvider';

const OrganizerView = () => {
    const location = useLocation();
    const { firstName } = location.state || {};

    return (
        <EventsProvider>
            <div>
                <Header firstName={firstName} />
                <div style={{ display: 'flex' }}>
                    <Sidebar />
                    <div style={{ marginLeft: '250px', padding: '20px', width: '100%' }}>
                        <Outlet />
                    </div>
                </div>
            </div>
        </EventsProvider>
    );
};

export default OrganizerView;