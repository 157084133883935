import React from 'react';
import './App.css';
import { Route, Routes } from 'react-router-dom';
import Header from './components/HeaderComponents/Header';
import Slideshow from './components/Mainpage/Slideshow';
import EventList from './components/Mainpage/EventList';
import Login from './components/LoginView/Login';
import Register from './components/RegisterView/Register';
import AdminView from './components/AdminView/AdminView';
import OrganizerView from './components/OrganizerView/OrganizerView';
import EventDetailedView from './components/EventDetailedView/EventDetailedView';
import SeatingPlanView from './components/SeatingPlanView/SeatingPlanView';
import QRCodeDemo from "./components/QR_CodeDemo/QRCode";
import ScanQRCode from "./components/QR_CodeDemo/scanqrcode";
import MolliePaymentDemo from "./components/MolliePayment/paymentDemo";
import OrderOverview from './components/OrderOverview/OrderOverview.jsx';
import SeatingPlanEditor from "./components/SeatingPlanEditor/SeatingPlanEditor";

import Overview from './components/OrganizerView/SidebarPages/Overview';
import Events from './components/OrganizerView/SidebarPages/Events';
import Members from './components/OrganizerView/SidebarPages/Members';
import SeatingPlanEditorOrganizer from "./components/OrganizerView/SidebarPages/SeatingPlanEditorOrganizer";

const App = () => {
    console.log('Rendering App');
    return (
        <div className="App">
            <main>
                <Routes>
                    <Route path="/" element={
                        <>  <Header />
                            <h1 id="welcome_label">Willkommen!</h1>
                            <Slideshow />
                            <EventList />
                        </>
                    } />
                    <Route path="/login" element={<Login />} />
                    <Route path="/register" element={<Register />} />
                    <Route path="/admin" element={<AdminView />} />

                    {/* OrganizerView Routen */}
                    <Route path="/organizer" element={<OrganizerView />}>
                        <Route path="overview" element={<Overview />} />
                        <Route path="events" element={<Events />} />
                        <Route path="members" element={<Members />} />
                        <Route path="seatingPlanEditor" element={<SeatingPlanEditorOrganizer />} />
                    </Route>

                    <Route path="/eventDetails" element={<EventDetailedView />}/>
                    <Route path="/seatingPlan" element={<SeatingPlanView />} />
                    <Route path="/paymentDemo" element={<MolliePaymentDemo />} />
                    <Route path="/qrcode" element={<QRCodeDemo />} />
                    <Route path="/scanqrcode" element={<ScanQRCode />} />
                    <Route path="/orderoverview" element={<OrderOverview />} />
                    <Route path="/seatingPlanEditor" element={<SeatingPlanEditor/>}/>
                </Routes>
            </main>
        </div>
    );
};

export default App;