import React, { useState } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Header from "../HeaderComponents/Header";
import urlConfig from '../../urlConfig';

const Login = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({username: '', password: ''});
    const [firstName, setFirstName] = useState('');
    const [userType, setUserType] = useState('');
    const location = useLocation();

    const redirection = location.state?.redirectTo || "/";
    const seatingPlanState = location.state?.seatingPlanState || {};

    const handleChange = (e) => {
        setFormData({...formData, [e.target.name]: e.target.value});
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const customerData = {username: formData.username, password: formData.password};

        try {
            const response = await fetch(`${urlConfig.apiBaseUrl}/auth/login`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(customerData),
            });

            const data = await response.json();

            if (response.ok) {
                const token = `${data.tokenType}${data.token}`;
                localStorage.setItem('token', token);
                localStorage.setItem('userType', data.type);

                if (data.type === 'CUSTOMER') {
                    setFirstName(data.customer.firstName);
                    localStorage.setItem('user', JSON.stringify(data.customer));
                    navigate(redirection, {state: seatingPlanState});
                } else if (data.type === 'ORGANIZER') {
                    setFirstName(data.organizer.name);
                    localStorage.setItem('user', JSON.stringify(data.organizer));
                    navigate('/organizer/overview', {state: {organizer: data.organizer, firstName: data.organizer.name}});
                } else if (data.type === 'ADMIN') {
                    setFirstName("Master");
                    localStorage.setItem('user', "Master");
                    navigate('/admin');
                }
                setUserType(data.type);
            } else {
                alert('Login fehlgeschlagen!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Ein Fehler ist aufgetreten!');
        }
    };

    return (
        <div className="flex flex-col bg-white-200 min-h-screen">
            <Header firstName={firstName} userType={userType}/>
            <div className="flex justify-center items-center h-screen">
                <div className="w-full max-w-md p-5">
                    <div className="bg-white p-10 shadow-xl rounded-xl text-center">
                        <h2 className="text-4xl font-bold mb-5 mt-7 ml-2">Login</h2>
                        <form onSubmit={handleSubmit} className="flex flex-col items-center">
                            <input
                                type="email"
                                placeholder="Email"
                                name="username"
                                onChange={handleChange}
                                required
                                className="w-full p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                            />
                            <input
                                type="password"
                                placeholder="Password"
                                name="password"
                                onChange={handleChange}
                                required
                                className="w-full p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                            />
                            <button
                                type="submit"
                                className="w-full p-3 mb-4 font-semibold bg-blue-600 text-white rounded-lg text-lg hover:bg-blue-700 focus:outline-none"
                            >
                                Login
                            </button>
                        </form>
                        <div className="mt-4 text-lg">
                            <p>Or continue with:</p>
                            <div className="flex justify-between mt-4">
                                <button
                                    className="w-1/2 p-3 m-3 bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none"
                                    onClick={() => navigate('/admin')}
                                >
                                    Google
                                </button>
                                <button
                                    className="w-1/2 p-3 m-3 bg-red-600 text-white rounded-lg hover:bg-red-700 focus:outline-none"
                                    onClick={() => navigate('/organizer/overview')}
                                >
                                    Meta
                                </button>
                            </div>
                        </div>
                        <div className="mt-4 text-lg">
                            <p>Don't have an account? <Link to="/register" className="text-blue-500 hover:underline">Create
                                one</Link></p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Login;