import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Container, Grid, Tabs, Tab, Box } from '@mui/material';
import AddEventComponent from "../componets/addEventComponent";
import EditEventComponent from "../componets/editEventComponent";
import EditEventDateComponent from "../componets/editEventDateComponent";
import EventOverviewComponent from "../componets/eventOverviewComponent";
import { useEventsProvider } from '../EventsProvider';
import LocationEditorComponent from "../componets/LocationEditorComponent";

const Events = () => {
    const { eventsData } = useEventsProvider();
    const [events, setEvents] = useState( eventsData || []);
    const [activeTab, setActiveTab] = useState(0);
    const location = useLocation();

    useEffect(() => {  // event aktualisieren onChange von eventsData
        if (eventsData) {
            setEvents(eventsData);
        }
    }, [eventsData]);

    useEffect(() => {  // Überprüfen, ob Daten über den state übergeben wurden
        if (location.state && location.state.events) {
            const { events } = location.state.events;
            setEvents(events || []);
        }

        // Aktiven Tab initialisieren (falls übergeben)
        if (location.state && location.state.activeTab !== undefined) {
            setActiveTab(location.state.activeTab);
        }
    }, [location.state]);

    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    return (
        <Container disableGutters className="mt-[6%]" maxWidth={false}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', marginBottom: '1rem' }}>
                <Tabs value={activeTab} onChange={handleTabChange} aria-label="Event Tabs">
                    <Tab label="Ihre Events" />
                    <Tab label="Hinzufügen" />
                    <Tab label="Event allgemeines" />
                    <Tab label="Termin spezifisches" />
                    <Tab label="Standorte verwalten" />
                </Tabs>
            </Box>
            <Grid container spacing={2}>
                {activeTab === 0 && (
                    <Grid item xs={12}>
                        <EventOverviewComponent events={events} activeTab={activeTab}/>
                    </Grid>
                )}
                {activeTab === 1 && (
                    <Grid item xs={12}>
                        <AddEventComponent events={events} activeTab={activeTab} />
                    </Grid>
                )}
                {activeTab === 2 && (
                    <Grid item xs={12}>
                        <EditEventComponent events={events} activeTab={activeTab} />
                    </Grid>
                )}
                {activeTab === 3 && (
                    <Grid item xs={12}>
                        <EditEventDateComponent events={events} activeTab={activeTab} />
                    </Grid>
                )}
                {activeTab === 4 && (
                    <Grid item xs={12}>
                        <LocationEditorComponent events={events} activeTab={activeTab} />
                    </Grid>
                )}
            </Grid>
        </Container>
    );
};

export default Events;