import React, {useState} from 'react';
import Header from "../HeaderComponents/Header";
import {useNavigate, useLocation, Link} from 'react-router-dom';
import urlConfig from '../../urlConfig';

const CheckoutPage = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const {selectedSeats, date, eventid, totalAmount} = location.state || {};

    const [formType, setFormType] = useState(null); // 'guest', 'register', 'login'

    const [Customer, setCustomer] = useState(JSON.parse(localStorage.getItem("user")));

    // ---- REGISTER ----

    const [formDataRegister, setFormDataRegister] = useState({
        firstName: '',
        lastName: '',
        address: '',
        email: '',
        password: '',
    });

    const handleChangeRegister = (e) => {
        setFormDataRegister({
            ...formDataRegister,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmitRegister = async (e) => {
        e.preventDefault();

        const customerDataRegister = {
            firstName: formDataRegister.firstName,
            lastName: formDataRegister.lastName,
            address: formDataRegister.address,
            email: formDataRegister.email,
            password: formDataRegister.password,
        };
        console.log(JSON.stringify(customerDataRegister));
        try {
            const response = await fetch(`${urlConfig.apiBaseUrl}/auth/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(customerDataRegister)
            });

            if (response.ok) {
                alert('Registrierung erfolgreich!');
            } else {
                alert('Registrierung fehlgeschlagen!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Ein Fehler ist aufgetreten!');
        }
    };

    // ---- LOGIN ----

    const [formDataLogin, setFormDataLogin] = useState({username: '', password: ''});

    const handleChangeLogin = (e) => {
        setFormDataLogin({...formDataLogin, [e.target.name]: e.target.value});
    };

    const handleSubmitLogin = async (e) => {
        e.preventDefault();

        const customerDataLogin = {username: formDataLogin.username, password: formDataLogin.password};


        try {
            const response = await fetch(`${urlConfig.apiBaseUrl}/auth/login`, {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(customerDataLogin),
            });

            const data = await response.json();

            if (response.ok) {
                const token = `${data.tokenType}${data.token}`;
                localStorage.setItem('token', token);
                localStorage.setItem('userType', data.type);

                if (data.type === 'CUSTOMER') {
                    localStorage.setItem('user', JSON.stringify(data.customer));
                    setCustomer(data.customer)
                } else if (data.type === 'ORGANIZER') {
                    localStorage.setItem('user', JSON.stringify(data.organizer));
                } else if (data.type === 'ADMIN') {
                    localStorage.setItem('user', "Master");
                }
            } else {
                alert('Login fehlgeschlagen!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Ein Fehler ist aufgetreten!');
        }
    };

    // ---- GUEST ----

    const [formDataGuest, setFormDataGuest] = useState({
        firstName: '',
        lastName: '',
        address: '',
        email: '',
    });

    const handleChangeGuest = (e) => {
        setFormDataGuest({
            ...formDataGuest,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmitGuest = async (e) => {
        e.preventDefault();

        const customerDataGuest = {
            firstName: formDataGuest.firstName,
            lastName: formDataGuest.lastName,
            address: formDataGuest.address,
            email: formDataGuest.email,
            guest: true
        };
        console.log(JSON.stringify(customerDataGuest));
        try {
            const response = await fetch(`${urlConfig.apiBaseUrl}/Customer`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(customerDataGuest)
            });

            const data = await response.json();

            if (response.ok) {
                alert('Gast-Registrierung erfolgreich!');

                setCustomer(data);

                /*if (data.type === 'CUSTOMER') {
                    localStorage.setItem('user', JSON.stringify(data.customer));
                } else if (data.type === 'ORGANIZER') {
                    localStorage.setItem('user', JSON.stringify(data.organizer));
                } else if (data.type === 'ADMIN') {
                    localStorage.setItem('user', "Master");
                }

                 */

            } else {
                alert('Gast-Registrierung fehlgeschlagen!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Ein Fehler ist aufgetreten!');
        }
    };


    // ---- PAYMENT ----

    const handlePayment = async () => {

        if (!Customer) {
            navigate("/login", {
                state: {
                    redirectTo: "/orderoverview",
                    seatingPlanState: {selectedSeats, date, eventid},
                }
            });
            return;
        }

        try {
            console.log(Customer.id)
            // API-Call zur Erstellung der Zahlung und Übergabe der Sitzplatzinformationen
            const response = await fetch(`${urlConfig.apiBaseUrl}/payment/create`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    amount: totalAmount,
                    customerID: Customer.id,
                    eventID: eventid,
                    dateID: date.id,
                    seatIDs: selectedSeats.map(seat => seat.id)
                }),
            });

            const data = await response.json();

            if (data.checkoutUrl) {
                window.location.assign(data.checkoutUrl);
            } else {
                throw new Error('No checkout URL received');
            }
        } catch (err) {
            console.error('Payment initiation failed:', err);
            alert("Failed to start the payment process. Please try again.");
        }
    };

    // ---- RETURN ----

    return (
        <div className="flex">
            <Header/>
            <div className="mt-16 w-1/2 p-4">

                {Customer ? (
                    <div>
                        <h2>Hallo {Customer.firstName}!</h2>
                        <p>Sie sind bereits angemeldet.</p>
                    </div>
                ) : (
                    <>

                        <h2>Registrierung/Login</h2>

                        {/* Auswahl zwischen Gast, Registrierung und Anmeldung */}
                        <div className="mb-4 mt-5">
                            <label className="mr-4">
                                <input
                                    type="radio"
                                    name="formType"
                                    value="register"
                                    onChange={() => setFormType('register')}
                                /> Registrieren
                            </label>
                            <label>
                                <input
                                    type="radio"
                                    name="formType"
                                    value="login"
                                    onChange={() => setFormType('login')}
                                /> Anmelden
                            </label>
                            <label className="mr-4">
                                <input
                                    type="radio"
                                    name="formType"
                                    value="guest"
                                    onChange={() => setFormType('guest')}
                                /> Als Gast bestellen
                            </label>
                        </div>

                        {/* Dynamische Anzeige von Formularfeldern basierend auf der Auswahl */}
                        {formType === 'guest' && (
                            <form onSubmit={handleSubmitGuest} className="flex flex-col items-center">

                                <input
                                    //<form onSubmit={handleSubmit}>
                                    type="text"
                                    name="firstName"
                                    placeholder="Vorname"
                                    value={formDataGuest.firstName}
                                    onChange={handleChangeGuest}
                                    required
                                    className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                />
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder="Nachname"
                                    value={formDataGuest.lastName}
                                    onChange={handleChangeGuest}
                                    required
                                    className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                />
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Adresse"
                                    value={formDataGuest.address}
                                    onChange={handleChangeGuest}
                                    required
                                    className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formDataGuest.email}
                                    onChange={handleChangeGuest}
                                    required
                                    className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                />
                                <button type="submit"
                                        className="w-1/2 p-3 mb-4 font-semibold bg-blue-600 text-white rounded-lg text-lg hover:bg-blue-700 focus:outline-none"
                                >Register
                                </button>
                            </form>
                        )}

                        {formType === 'register' && (

                            <form onSubmit={handleSubmitRegister} className="flex flex-col items-center">

                                <input
                                    //<form onSubmit={handleSubmit}>
                                    type="text"
                                    name="firstName"
                                    placeholder="Vorname"
                                    value={formDataRegister.firstName}
                                    onChange={handleChangeRegister}
                                    required
                                    className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                />
                                <input
                                    type="text"
                                    name="lastName"
                                    placeholder="Nachname"
                                    value={formDataRegister.lastName}
                                    onChange={handleChangeRegister}
                                    required
                                    className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                />
                                <input
                                    type="text"
                                    name="address"
                                    placeholder="Adresse"
                                    value={formDataRegister.address}
                                    onChange={handleChangeRegister}
                                    required
                                    className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                />
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Email"
                                    value={formDataRegister.email}
                                    onChange={handleChangeRegister}
                                    required
                                    className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                />
                                <input
                                    type="password"
                                    name="password"
                                    placeholder="Passwort"
                                    value={formDataRegister.password}
                                    onChange={handleChangeRegister}
                                    required
                                    className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                />
                                <button type="submit"
                                        className="w-1/2 p-3 mb-4 font-semibold bg-blue-600 text-white rounded-lg text-lg hover:bg-blue-700 focus:outline-none"
                                >Registrieren
                                </button>
                            </form>
                        )}

                        {formType === 'login' && (

                            <form onSubmit={handleSubmitLogin} className="flex flex-col items-center">
                                <input
                                    type="email"
                                    placeholder="Email"
                                    name="username"
                                    onChange={handleChangeLogin}
                                    required
                                    className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                />
                                <input
                                    type="password"
                                    placeholder="Password"
                                    name="password"
                                    onChange={handleChangeLogin}
                                    required
                                    className="w-1/2 p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                                />
                                <button
                                    type="submit"
                                    className="w-1/2 p-3 mb-4 font-semibold bg-blue-600 text-white rounded-lg text-lg hover:bg-blue-700 focus:outline-none"
                                >
                                    Login
                                </button>
                            </form>
                        )}
                    </>
                )}
            </div>

            <div className="mt-16 w-1/2 p-4 flex flex-col items-center">
                <h2>Bestellübersicht</h2>
                <ul className="w-full flex flex-col items-center">
                    {selectedSeats.map((seat) => (
                        <li key={seat.id}
                            className="border border-gray-500 bg-white-400 p-4 my-2 shadow-sm rounded-md w-1/2 flex items-center justify-center">
                            <div className="flex items-center space-x-4">
                                <span className="px-2 py-1">Reihe: {seat.rowNumber}</span>
                                <span className="px-2 py-1">Sitz: {seat.seatNumber}</span>
                                <span className="px-2 py-1">Gruppe: {seat.group}</span>
                                <span className="px-2 py-1">Preis: {seat.price}</span>
                            </div>
                        </li>
                    ))}
                </ul>
                <h3>Gesamtbetrag: €{totalAmount}</h3>
                <button
                    onClick={handlePayment}
                    className="mt-auto bg-blue-500 text-white py-2 px-4 rounded cursor-pointer"
                >
                    Zahlung starten
                </button>
            </div>
        </div>
    );
};

export default CheckoutPage;
