import React, { useEffect } from 'react';
import SeatingPlanEditor from "../../SeatingPlanEditor/SeatingPlanEditor";
import { useLocation } from "react-router-dom";

const SeatingPlanEditorOrganizer = () => {
    const location = useLocation();

    const events = location.state?.events;

    const selectedEventObject = location.state?.selectedEvent;
    const selectedEventName = selectedEventObject?.name;

    const selectedDate = "Servas";  // TODO richtigstellen

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    return (
        <div className="mt-[5%]">
            <SeatingPlanEditor events={events} selectedEvent={selectedEventName} selectedDate={selectedDate}></SeatingPlanEditor>
        </div>
    );
};

export default SeatingPlanEditorOrganizer;