import React, {useEffect, useRef, useState} from "react";
import SeatingGroup from "./SeatingGroup";
import Stage from "./Stage";

const SeatingPlan = ({
                         seatingGroups, seatSize, minSpacing, svgWidth, svgHeight,
                         toggleSeat, selectedSeats, stages, handleElementClick, selectedElement
                     }) => {

    const groupRefs = useRef([]); // Array of refs for each group
    const [groupDimensions, setGroupDimensions] = useState({}); // Store dimensions for each group

    useEffect(() => {
        // Measure each group's bounding box after rendering
        const updatedDimensions = {};
        groupRefs.current.forEach((ref, index) => {
            if (ref) {
                const bbox = ref.getBBox(); // Measure the bounding box
                updatedDimensions[index] = {
                    width: bbox.width,
                    height: bbox.height,
                    x: bbox.x,
                    y: bbox.y,
                };
            }
        });
        setGroupDimensions(updatedDimensions);
    }, [seatingGroups]);

    const handleSvgClick = (event) => {
        // Check if the click target is the <svg> itself
        if (event.target === event.currentTarget) {
            handleElementClick(selectedElement);
        }
    };

    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={svgWidth} height={svgHeight} onClick={handleSvgClick}>

            {/* Stage Rendering */}
            <g>
                {stages.map((stageInfo, index) => (
                        <Stage key={`stage-${index}`}
                               stageInfo={stageInfo}
                               svgWidth={svgWidth}
                               svgHeight={svgHeight}
                               handleElementClick={() => handleElementClick(stageInfo)}
                               selectedElement={selectedElement}
                        />
                    )
                )}
            </g>

            {/* Seating Groups */
            }
            <g>
                {seatingGroups.map((group, groupIndex) =>
                    <SeatingGroup
                        key={`group-${groupIndex}`}
                        group={group}
                        groupIndex={groupIndex}
                        svgWidth={svgWidth}
                        svgHeight={svgHeight}
                        seatSize={seatSize}
                        minSpacing={minSpacing}
                        toggleSeat={toggleSeat}
                        selectedSeats={selectedSeats}
                        handleElementClick={() => handleElementClick(group)}
                        selectedElement={selectedElement}
                    />)}
            </g>

        </svg>
    );
};

export default SeatingPlan
