import { Button, Divider, Grid, Input, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";

const LocationEditorComponent = () => {
    const [customLocations, setCustomLocations] = useState([]);
    const [locationObject, setLocationObject] = useState({});
    const locationId = "locationList";

    // Lade initiale Daten aus localStorage nur einmal
    useEffect(() => {
        const savedData = localStorage.getItem(locationId.toString());
        if (savedData) {
            setCustomLocations(Object.values(JSON.parse(savedData)));
        }
    }, []); // Der leere Abhängigkeits-Array sorgt dafür, dass dieser Effekt nur einmal ausgeführt wird

    const addCustomLocation = () => {
        setCustomLocations([
            ...customLocations,
            { name: '', image: null }
        ]);
    };

    const updateLocation = (index, field, value) => {
        const updatedLocations = [...customLocations];
        updatedLocations[index][field] = value;
        setCustomLocations(updatedLocations);
    };

    const removeLocation = (index) => {
        setCustomLocations(customLocations.filter((_, i) => i !== index));
    };

    const handleImageChange = (index, event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onload = () => {
                updateLocation(index, 'image', reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    // Speichere customLocations in localStorage nur wenn sich customLocations ändern
    useEffect(() => {
        if (customLocations.length > 0) {
            const updatedLocationObject = {};
            customLocations.forEach((location, index) => {
                updatedLocationObject[`location${index}`] = {
                    name: location.name,
                    image: location.image
                };
            });

            setLocationObject(updatedLocationObject);

            // Speichern in localStorage, wenn locationId existiert
            localStorage.setItem(locationId.toString(), JSON.stringify(updatedLocationObject));
        }
    }, [customLocations]); // Wird jedes Mal aufgerufen, wenn customLocations sich ändern

    return (
        <div>
            {/* Locations verwalten */}
            <Grid item xs={12}>
                <Typography variant="h6" gutterBottom>
                    Standorte verwalten
                </Typography>

                {customLocations.length === 0 ? (
                    <Typography variant="body1" color="textSecondary">
                        Sie <strong>müssen</strong> noch mindestens einen Standort anlegen!
                    </Typography>
                ) : (
                    customLocations.map((customLocation, index) => (
                        <Grid
                            container
                            spacing={2}
                            alignItems="center"
                            key={index}
                            style={{ marginTop: '15px' }}
                        >
                            <Grid item xs={12} sm={5}>
                                <TextField
                                    label="Name des Standorts"
                                    fullWidth
                                    value={customLocation.name}
                                    onChange={(e) => updateLocation(index, 'name', e.target.value)}
                                />
                            </Grid>
                            <Grid item xs={12} sm={5}>
                                {/* Image Chooser */}
                                <Input
                                    type="file"
                                    accept="image/*"
                                    onChange={(e) => handleImageChange(index, e)}
                                    fullWidth
                                />
                                {customLocation.image && (
                                    <img
                                        src={customLocation.image}
                                        alt={`Preview for ${customLocation.name}`}
                                        style={{
                                            marginTop: '10px',
                                            width: '100%',
                                            maxHeight: '150px',
                                            objectFit: 'cover'
                                        }}
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <Button
                                    variant="outlined"
                                    color="error"
                                    fullWidth
                                    onClick={() => removeLocation(index)}
                                >
                                    Entfernen
                                </Button>
                            </Grid>
                        </Grid>
                    ))
                )}

                <Button
                    variant="contained"
                    color="primary"
                    sx={{ marginTop: 2 }}
                    onClick={addCustomLocation}
                    style={{ marginTop: '2%' }}
                >
                    Neuen Standort hinzufügen
                </Button>
            </Grid>
        </div>
    );
};

export default LocationEditorComponent;