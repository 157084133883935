import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import Header from "../HeaderComponents/Header";
import urlConfig from '../../urlConfig';

const Register = () => {
    const navigate = useNavigate();
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        address: '',
        email: '',
        password: '',
    });

    const handleChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const customerData = {
            firstName: formData.firstName,
            lastName: formData.lastName,
            address: formData.address,
            email: formData.email,
            password: formData.password,
        };
        console.log(JSON.stringify(customerData));
        try {
            const response = await fetch(`${urlConfig.apiBaseUrl}/auth/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(customerData)
            });

            if (response.ok) {
                alert('Registrierung erfolgreich!');
                navigate('/login'); // Weiterleitung zum Login nach erfolgreicher Registrierung
            } else {
                alert('Registrierung fehlgeschlagen!');
            }
        } catch (error) {
            console.error('Error:', error);
            alert('Ein Fehler ist aufgetreten!');
        }
    };

    return (
        <div className="register-page">
            <Header/>
            <div className="flex justify-center items-center h-screen">
                <div className="w-full max-w-md p-5">
                    <div className="bg-white p-10 shadow-xl rounded-xl text-center">
                        <h2 className="text-4xl font-bold mb-5 mt-7 ml-2">Registrieren</h2>
                        <form onSubmit={handleSubmit}>
                            <input
                                type="text"
                                name="firstName"
                                placeholder="Vorname"
                                value={formData.firstName}
                                onChange={handleChange}
                                required
                                className="w-full p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                            />
                            <input
                                type="text"
                                name="lastName"
                                placeholder="Nachname"
                                value={formData.lastName}
                                onChange={handleChange}
                                required
                                className="w-full p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                            />
                            <input
                                type="text"
                                name="address"
                                placeholder="Adresse"
                                value={formData.address}
                                onChange={handleChange}
                                required
                                className="w-full p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                            />
                            <input
                                type="email"
                                name="email"
                                placeholder="Email"
                                value={formData.email}
                                onChange={handleChange}
                                required
                                className="w-full p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                            />
                            <input
                                type="password"
                                name="password"
                                placeholder="Passwort"
                                value={formData.password}
                                onChange={handleChange}
                                required
                                className="w-full p-3 mb-4 focus:border-blue-600 focus:outline-none text-lg border-b-2 border-0 border-solid border-gray-500 placeholder-gray-500"
                            />
                            <button type="submit"
                                    className="w-full p-3 mb-4 font-semibold bg-blue-600 text-white rounded-lg text-lg hover:bg-blue-700 focus:outline-none"
                            >Register
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Register;