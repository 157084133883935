import React, { useState } from "react";
import {
    Box,
    Button,
    TextField,
    Typography,
    Grid,
} from "@mui/material";

const AddSeatingGroup = ({ onAdd, onCancel }) => {
    const [name, setName] = useState("");
    const [rotation, setRotation] = useState(0);
    const [rows, setRows] = useState(1);
    const [seatsPerRow, setSeatsPerRow] = useState(1);

    const handleAdd = () => {
        if (!name.trim()) {
            alert("Bitte geben Sie einen Namen ein.");
            return;
        }

        onAdd({
            name,
            rotation: parseFloat(rotation),
            rows: parseInt(rows, 10),
            seatsPerRow: parseInt(seatsPerRow, 10),
        });
    };

    return (
        <Box
            sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                p: 2,
                backgroundColor: "background.paper",
                borderRadius: 2,
                boxShadow: 3,
            }}
        >
            <Typography variant="h6" gutterBottom>
                Neue Sitzgruppe hinzufügen
            </Typography>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Name der Sitzgruppe"
                        variant="outlined"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Rotation (Grad)"
                        type="number"
                        variant="outlined"
                        value={rotation}
                        onChange={(e) => setRotation(e.target.value)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Anzahl der Reihen"
                        type="number"
                        variant="outlined"
                        value={rows}
                        onChange={(e) => setRows(e.target.value)}
                        inputProps={{ min: 1 }}
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        label="Sitze pro Reihe"
                        type="number"
                        variant="outlined"
                        value={seatsPerRow}
                        onChange={(e) => setSeatsPerRow(e.target.value)}
                        inputProps={{ min: 1 }}
                    />
                </Grid>
            </Grid>
            <Box
                sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: 2,
                    mt: 2,
                }}
            >
                <Button variant="contained" color="primary" onClick={handleAdd}>
                    Hinzufügen
                </Button>
                <Button variant="outlined" color="secondary" onClick={onCancel}>
                    Abbrechen
                </Button>
            </Box>
        </Box>
    );
};

export default AddSeatingGroup;
