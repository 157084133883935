import React, {useState, useRef, useEffect} from "react";
import {Box, Grid, Select, MenuItem, Button, FormControl, InputLabel, Paper} from "@mui/material";
import SeatingPlan from "./components/SeatingPlan";
import EditorPanel from "./EditorPanel";
import {useEventsProvider} from "../OrganizerView/EventsProvider";
import urlConfig from "../../urlConfig";

let idCounter = 0;
const generateSvgId = () => {
    idCounter += 1;
    return idCounter;
};

const SeatingPlanEditor = ({events, selectedEvent: selectedEventName, selectedDate: selectedDate}) => {
    const {onEventChange} = useEventsProvider();
    const [seatingGroups, setSeatingGroups] = useState([]);
    const [stages, setStages] = useState([]);
    const [selectedElement, setSelectedElement] = useState(null);
    const [svgDimensions, setSvgDimensions] = useState({width: 0, height: 0});
    const [currentEvent, setCurrentEvent] = useState(
        events.find((event) => event.name === selectedEventName) || null
    );
    const [currentDate, setCurrentDate] = useState(selectedDate || "");
    const seatingPlanContainerRef = useRef(null);

    useEffect(() => {
        const updateDimensions = () => {
            if (seatingPlanContainerRef.current) {
                const {offsetWidth, offsetHeight} = seatingPlanContainerRef.current;
                setSvgDimensions({width: offsetWidth, height: offsetHeight});
            }
        };

        updateDimensions();
        window.addEventListener("resize", updateDimensions);

        // Verhindere das Scrollen auf der gesamten Seite
        document.body.style.overflow = "hidden";

        return () => {
            window.removeEventListener("resize", updateDimensions);
            document.body.style.overflow = "auto"; // Stelle sicher, dass das Scrollen bei der Rückkehr aktiviert wird
        };
    }, []);

    useEffect(() => {
        // Wenn sich das Event oder Datum ändert, laden wir die entsprechenden seatingGroups und stages
        if (currentEvent && currentDate) {
            const selectedEventDate = currentEvent.eventDates.find(
                (date) => date.date === currentDate
            );
            if (selectedEventDate && selectedEventDate.seatingPlan) {
                // Setze die seatingGroups und stages aus dem ausgewählten Datum
                setSeatingGroups(selectedEventDate.seatingPlan.seatingGroups || []);
                setStages(selectedEventDate.seatingPlan.stages || []);
            }
        }
    }, [currentEvent, currentDate]);

    const handleAddSeatingGroup = ({name, rotation, rows, seatsPerRow}) => {
        const newGroup = {
            type: "seatingGroup",
            svgId: generateSvgId(),
            name: name,
            rows: Array.from({length: rows}, (_, rowIndex) => ({
                rowNumber: rowIndex + 1,
                seatOffset: 0,
                seats: Array.from({length: seatsPerRow}, (_, seatIndex) => ({
                    seatNumber: seatIndex + 1,
                    type: {name: "Standard", price: 0},
                    status: "AVAILABLE",
                })),
            })),
            designInformation: {
                rotation: rotation,
                relativeCenterX: 0.5,
                relativeCenterY: 0.5,
                orientation: "LEFT",
                showName: true,
            },
        };

        setSeatingGroups([...seatingGroups, newGroup]);
        setSelectedElement(newGroup);
    };

    const addStage = () => {
        const newStage = {
            svgId: generateSvgId(),
            type: "stage",
            relativeCenterX: 0.6,
            relativeCenterY: 0.2,
            relativeSizeX: 70,
            relativeSizeY: 30,
            text: "Stage",
            fontSize: 40,
            stageOpacity: 70,
        };
        setStages([...stages, newStage]);
        setSelectedElement(newStage);
    };

    const handleElementClick = (element) => {
        if (!element) {
            return;
        }
        setSelectedElement((prev) => (prev?.svgId === element.svgId ? null : element));
    };

    const updateElement = (updatedElement) => {
        if (updatedElement.type === "seatingGroup") {
            setSeatingGroups((prev) =>
                prev.map((group) => (group.svgId === updatedElement.svgId ? {...updatedElement} : group))
            );
        } else if (updatedElement.type === "stage") {
            setStages((prev) =>
                prev.map((stage) => (stage.svgId === updatedElement.svgId ? {...updatedElement} : stage))
            );
        }
        setSelectedElement({...updatedElement});
    };

    const saveSeatingPlan = () => {
        if (currentEvent && currentDate) {
            // Finde das Datum, das dem aktuellen Event und Datum entspricht
            const updatedEventDates = currentEvent.eventDates.map((dateObj) => {
                if (dateObj.date === currentDate) {
                    // Aktualisiere den Sitzplan mit den neuen Gruppen und Bühnen
                    return {
                        ...dateObj,
                        seatingPlan: {
                            seatingGroups, // Die aktuellen Sitzgruppen
                            stages,        // Die aktuellen Bühnen
                        },
                    };
                }
                return dateObj;
            });

            // Erstelle eine Kopie des Events mit den aktualisierten Daten
            const updatedEvent = {
                ...currentEvent,
                eventDates: updatedEventDates,
            };
            // Sendet die Daten an den Server
            fetch(`${urlConfig.apiBaseUrl}/Date/${currentEvent.id}`, {
                method: 'PUT',  // Verwende PUT für das Aktualisieren
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    seatingGroups: seatingGroups,
                    stages: stages,
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        console.log("Seating Plan erfolgreich gespeichert!");
                        alert("Sitzplan erfolgreich gespeichert!");
                        // Optional: Eventuell den globalen Zustand oder den Event-Daten aktualisieren
                        onEventChange(updatedEvent);
                    } else {
                        console.error("Fehler beim Speichern des Sitzplans:", response.statusText);
                        alert("Fehler beim Speichern des Sitzplans.");
                    }
                })
                .catch((error) => {
                    console.error("Netzwerkfehler:", error);
                    alert("Fehler beim Speichern des Sitzplans.");
                });
        }
    };


    return (
        <Box sx={{display: "flex", flexDirection: "column", height: "100vh", bgcolor: "#f7f9fc"}}>
            {/* Dropdown Section */}
            <Paper sx={{padding: 2, display: "flex", gap: 2, alignItems: "center", justifyContent: "space-between"}}>
                <Box sx={{display: "flex", gap: 2}}>
                    <FormControl sx={{minWidth: 200}}>
                        <InputLabel>Event</InputLabel>
                        <Select
                            value={currentEvent?.name || ""}
                            onChange={(e) => {
                                const selectedEvent = events.find((event) => event.name === e.target.value);
                                setCurrentEvent(selectedEvent || null);
                                setCurrentDate(""); // Reset date when event changes
                            }}
                            label="Event"
                        >
                            <MenuItem value="">
                                <em>Wähle ein Event</em>
                            </MenuItem>
                            {events.map((event) => (
                                <MenuItem key={event.id} value={event.name}>
                                    {event.name}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>

                    <FormControl sx={{minWidth: 200}}>
                        <InputLabel>Datum</InputLabel>
                        <Select
                            value={currentDate}
                            onChange={(e) => setCurrentDate(e.target.value)}
                            label="Datum"
                            disabled={!currentEvent}
                        >
                            <MenuItem value="">
                                <em>Wähle ein Datum</em>
                            </MenuItem>
                            {currentEvent?.eventDates?.map((dateObj) => (
                                <MenuItem key={dateObj.id} value={dateObj.date}>
                                    {new Date(dateObj.date).toLocaleString()}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Box>

                <Button
                    onClick={saveSeatingPlan}
                    variant="contained"
                    color="primary"
                    disabled={!currentEvent || !currentDate}
                >
                    Speichern
                </Button>
            </Paper>

            {/* Main Editor */}
            <Grid container sx={{flex: 1}}>
                {/* EditorPanel wird nur angezeigt, wenn ein Datum und Event ausgewählt wurden */}
                {currentEvent && currentDate && (
                    <>
                        <Grid
                            item
                            xs={3}
                            sx={{
                                bgcolor: "#e8f0fe",
                                padding: 2,
                                height: "100vh",
                                position: "relative",
                                overflowY: "auto", // Hier wird das Scrollen aktiviert
                            }}
                        >
                            <EditorPanel
                                addStage={addStage}
                                selectedElement={selectedElement}
                                updateElement={updateElement}
                                onAddSeatingGroup={handleAddSeatingGroup}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={9}
                            ref={seatingPlanContainerRef}
                            sx={{position: "relative", overflow: "hidden"}}
                        >
                            <SeatingPlan
                                seatingGroups={seatingGroups}
                                stages={stages}
                                seatSize={20}
                                minSpacing={10}
                                handleElementClick={handleElementClick}
                                selectedElement={selectedElement}
                                svgWidth={svgDimensions.width}
                                svgHeight={svgDimensions.height}
                            />
                        </Grid>
                    </>
                )}
            </Grid>
        </Box>
    );
};

export default SeatingPlanEditor;
