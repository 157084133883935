import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    Card,
    Collapse,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography
} from "@mui/material";
import {ExpandLess} from "@mui/icons-material";
import {styled} from "@mui/material/styles";
import {useNavigate, useLocation} from "react-router-dom";

const EditEventDateComponent = ({events: eventsProp, locations: locationsProp}) => {
    // CONSTANTS
    const navigate = useNavigate();
    const [events, setEvents] = useState(eventsProp);
    const routerLocation = useLocation();

    // State-Daten aus der Location auslesen
    useEffect(() => {
        setEvents(eventsProp);

        // Wenn ein Event-Name übergeben wurde, suche das passende Event-Objekt
        if (routerLocation.state?.selectedEvent) {
            const eventName = routerLocation.state.selectedEvent;
            const matchedEvent = eventsProp.find(event => event.name === eventName);
            setSelectedEvent(matchedEvent || null);
        }
    }, [eventsProp, routerLocation.state]);

    const [locations, setLocations] = useState(locationsProp);

    // EDIT EVENT CONSTANTS
    const [selectedEvent, setSelectedEvent] = React.useState(null);

    const defaultSeatClass = {name: "Standard", price: 10, category: "Standard"};
    const [seatClasses, setSeatClasses] = useState([defaultSeatClass]);

    const updateSeatClass = (index, field, value) => {
        const updatedSeatClasses = [...seatClasses];
        updatedSeatClasses[index][field] = value;
        setSeatClasses(updatedSeatClasses);
    };

    const addSeatClass = () => {
        setSeatClasses([...seatClasses, {name: '', price: ''}]);
    };

    const removeSeatClass = (index) => {
        setSeatClasses(seatClasses.filter((_, i) => i !== index));
    };

    // EDIT TERMIN CONSTANTS
    const [expandedIndex, setExpandedIndex] = useState(null);

    const toggleExpand = (index) => {
        setExpandedIndex(expandedIndex === index ? null : index);
    };

    const handleDeleteEvent = (index) => {
        const updatedEventDates = selectedEvent.eventDates.filter((_, i) => i !== index);
        setSelectedEvent({...selectedEvent, eventDates: updatedEventDates});
    };

    const ExpandMore = styled((props) => {
        const {expand, ...other} = props;
        return <IconButton {...other} />;
    })(({theme, expand}) => ({
        transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    }));

    const demoLocations = [
        { id: 1, description: 'Berlin' },
        { id: 2, description: 'München' },
        { id: 3, description: 'Hamburg' },
        { id: 4, description: 'Frankfurt' },
        { id: 5, description: 'Daheim' },
    ];
    const [selectedLocation, setSelectedLocation] = useState('');

    return (
        <div>
            {/* Event bearbeiten */}
            <Grid item xs={12} sm={12} style={{marginBottom: '3%'}}>
                <Card sx={{borderRadius: '1rem', boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', padding: 2}}>
                    <Grid container spacing={2} alignItems="center">

                        {/* Titel */}
                        <Grid item xs={12} sm={12} textAlign="center">
                            <Typography variant="h5" gutterBottom>
                                Event bearbeiten
                            </Typography>
                        </Grid>

                        {/* Dropdown-Menüs */}
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                options={events}
                                getOptionLabel={(option) => option.name || ''}
                                onChange={(event, newValue) => {
                                    setSelectedEvent(newValue);
                                }}
                                value={selectedEvent || null}
                                renderInput={(params) => <TextField {...params} label="Event auswählen" fullWidth/>}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <Autocomplete
                                options={
                                    selectedEvent
                                        ? selectedEvent.eventDates || [] // Nur Termine des ausgewählten Events anzeigen
                                        : [{date: null, placeholder: "Bitte zuerst Event auswählen"}]
                                }
                                getOptionLabel={(option) => {
                                    if (option.date) {
                                        const date = new Date(option.date);
                                        const dayOfWeek = date.toLocaleString('de-DE', {weekday: 'long'}); // Wochentag ermitteln
                                        return `${date.toLocaleString()} (${dayOfWeek})`; // Datum mit Wochentag
                                    }
                                    return option.placeholder || '';
                                }}
                                onChange={(event, newValue) => console.log('Termin ausgewählt:', newValue)}
                                renderInput={(params) => <TextField {...params} label="Termin auswählen (notwendig)"
                                                                    fullWidth/>}
                                disabled={!selectedEvent}
                            />
                        </Grid>
                        <Typography variant="body1" color="textSecondary" style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '100%',
                            marginTop: '1.5%',
                            marginBottom: '3%'
                        }}>
                            Hinweis: Diese Einstellungen sind nur für den ausgewählten Termin des Events gültig.
                        </Typography>

                        <>
                            {selectedEvent && selectedEvent.eventDates?.length > 0 ? (
                                <>
                                    {/* Event Informationen bearbeiten */}
                                    {selectedEvent.eventDates.map((eventDate, index) => (
                                        <Grid
                                            container
                                            spacing={2}
                                            key={index}
                                            style={{
                                                marginBottom: '10px',
                                                alignItems: 'center',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            {/* Datum */}
                                            <Grid item xs={4}>
                                                <TextField
                                                    label="Datum"
                                                    type="date"
                                                    value={eventDate.date ? new Date(eventDate.date).toISOString().split('T')[0] : ''}
                                                    fullWidth
                                                    InputLabelProps={{shrink: true}}
                                                    onChange={(e) => {
                                                        const updatedEventDates = [...selectedEvent.eventDates];
                                                        updatedEventDates[index].date = e.target.value;
                                                        setSelectedEvent({
                                                            ...selectedEvent,
                                                            eventDates: updatedEventDates,
                                                        });
                                                    }}
                                                />
                                            </Grid>

                                            {/* Uhrzeit */}
                                            <Grid item xs={2}>
                                                <TextField
                                                    label="Uhrzeit"
                                                    type="time"
                                                    value={eventDate.date ? new Date(eventDate.date).toISOString().split('T')[1]?.substring(0, 5) : ''}
                                                    fullWidth
                                                    InputLabelProps={{shrink: true}}
                                                    onChange={(e) => {
                                                        const updatedEventDates = [...selectedEvent.eventDates];
                                                        const date = new Date(eventDate.date || new Date());
                                                        date.setHours(parseInt(e.target.value.split(':')[0], 10));
                                                        date.setMinutes(parseInt(e.target.value.split(':')[1], 10));
                                                        updatedEventDates[index].date = date.toISOString();
                                                        setSelectedEvent({
                                                            ...selectedEvent,
                                                            eventDates: updatedEventDates,
                                                        });
                                                    }}
                                                />
                                            </Grid>

                                            {/* Standort */}
                                            <Grid item xs={4}>
                                                <Autocomplete
                                                    value={selectedLocation}
                                                    onChange={(event, newValue) => setSelectedLocation(newValue)}
                                                    options={demoLocations}
                                                    getOptionLabel={(option) => option.description}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Standort"
                                                            placeholder="Standort eingeben"
                                                            fullWidth
                                                        />
                                                    )}
                                                    fullWidth
                                                />
                                            </Grid>

                                            {/* Pfeil zum Ein-/Ausklappen
                                            <Grid item xs={2} style={{textAlign: 'right'}}>
                                                <IconButton onClick={() => toggleExpand(index)}>
                                                    {expandedIndex === index ? <ExpandLess/> : <ExpandMore/>}
                                                </IconButton>
                                            </Grid>*/}

                                            {/* Eingeklappte Zusatzinformationen */}
                                            <Grid item xs={12}>
                                                <Collapse in={expandedIndex === index}>
                                                    <Grid container spacing={2} style={{marginTop: '10px'}}>
                                                        {/* Kurzbeschreibung */}
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="Kurzbeschreibung"
                                                                placeholder="Kurze Beschreibung eingeben"
                                                                fullWidth
                                                                value={eventDate.shortDescription || ''}
                                                                onChange={(e) => {
                                                                    const updatedEventDates = [...selectedEvent.eventDates];
                                                                    updatedEventDates[index].shortDescription = e.target.value;
                                                                    setSelectedEvent({
                                                                        ...selectedEvent,
                                                                        eventDates: updatedEventDates,
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>

                                                        {/* Langbeschreibung */}
                                                        <Grid item xs={6}>
                                                            <TextField
                                                                label="Langbeschreibung"
                                                                placeholder="Detaillierte Beschreibung eingeben"
                                                                fullWidth
                                                                multiline
                                                                rows={3}
                                                                value={eventDate.longDescription || ''}
                                                                onChange={(e) => {
                                                                    const updatedEventDates = [...selectedEvent.eventDates];
                                                                    updatedEventDates[index].longDescription = e.target.value;
                                                                    setSelectedEvent({
                                                                        ...selectedEvent,
                                                                        eventDates: updatedEventDates,
                                                                    });
                                                                }}
                                                            />
                                                        </Grid>

                                                        {/* Löschen-Button */}
                                                        <Grid item xs={12} style={{textAlign: 'right'}}>
                                                            <Button
                                                                variant="outlined"
                                                                color="error"
                                                                onClick={() => handleDeleteEvent(index)}
                                                            >
                                                                Termin löschen
                                                            </Button>
                                                        </Grid>
                                                    </Grid>
                                                </Collapse>
                                            </Grid>
                                        </Grid>
                                    ))}

                                    {/* Sitzklassen verwalten */}
                                    <Grid item xs={12}>
                                        <Divider sx={{marginY: 2}}/>
                                        <Typography variant="h6" gutterBottom>
                                            Sitzklassen verwalten
                                        </Typography>

                                        {seatClasses.length === 0 ? (
                                            <Typography variant="body1" color="textSecondary">
                                                Sie <strong>müssen</strong> noch mindestens eine Sitzklasse anlegen!
                                            </Typography>
                                        ) : (
                                            seatClasses.map((seatClass, index) => (
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    alignItems="center"
                                                    key={index}
                                                    style={{marginTop: '15px'}}
                                                >
                                                    <Grid item xs={12} sm={5}>
                                                        <TextField
                                                            label="Name der Sitzklasse"
                                                            fullWidth
                                                            value={seatClass.name}
                                                            onChange={(e) => updateSeatClass(index, 'name', e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={5}>
                                                        <TextField
                                                            label="Preis der Sitzklasse (€)"
                                                            type="number"
                                                            fullWidth
                                                            value={seatClass.price}
                                                            onChange={(e) => updateSeatClass(index, 'price', e.target.value)}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={12} sm={2}>
                                                        <Button
                                                            variant="outlined"
                                                            color="error"
                                                            fullWidth
                                                            onClick={() => removeSeatClass(index)}
                                                        >
                                                            Entfernen
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            ))
                                        )}

                                        <Button
                                            variant="contained"
                                            color="primary"
                                            sx={{marginTop: 2}}
                                            onClick={addSeatClass}
                                            style={{marginTop: '2%'}}
                                        >
                                            Neue Sitzklasse hinzufügen
                                        </Button>
                                    </Grid>

                                    {/* Saalplan bearbeiten */}
                                    <Grid item xs={12} style={{marginBottom: '2%'}}>
                                        <Divider sx={{marginY: 2, marginBottom: '3%'}}/>
                                        <Button
                                            variant="outlined"
                                            color="primary"
                                            onClick={() => navigate('/organizer/seatingPlanEditor')}
                                        >
                                            Saalplan Editor öffnen
                                        </Button>
                                        <Typography variant="body1" color="textSecondary" style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            width: '100%',
                                            marginTop: '5px',
                                        }}>
                                            Hinweis: Der hier erstellte Saalplan gilt für alle Termine des Events.
                                        </Typography>
                                    </Grid>
                                </>
                            ) : (
                                // Alternativer Text
                                <Grid item xs={12}>
                                    <Divider sx={{marginY: 2}}/>
                                    <Typography variant="body1" color="textSecondary">
                                        Bitte wählen Sie noch ein Event aus, welches Sie bearbeiten wollen!
                                    </Typography>
                                </Grid>
                            )}
                        </>
                    </Grid>
                </Card>
            </Grid>
        </div>
    );
}

export default EditEventDateComponent;
